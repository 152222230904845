import React, { useEffect, useState } from 'react'
import ShowAiPriceModal from './ShowAiProceModal'
import Button from '../../../shared/button'
import AiGradingItemsModal from './AiGradingItemsModal'
import { IUploadItems } from '../../../interfaces/agents/IUploadItems'
import { TAgents } from '../../../interfaces/ai-agents/TAgents'

const AIGrading = ({
	type,
	message
}: {
	type?: TAgents,
	message: string
}) => {
	const [showModal, setShowModal] = useState<boolean>()
	const [uploadedItems, setUploadedItems] = useState<IUploadItems[]>([]);

	const handleShowModal = () => setShowModal(modal => !modal)


	const handleMessages = async (event: any) => {
		if (event.data.uploadedItems) {
			const items = JSON.parse(event.data.uploadedItems) || {};
			setUploadedItems(allItems => [...allItems, items]);
			if (event.data.close) {
				// setIsLoading(true)
				setShowModal(false);
				// const uploaded = [...uploadedItems, items];
				// setUploadedItems([]);
				// try {
				// await handleSubmitAnswer(uploaded)
				// } catch (e) { }
				// finally {
				// setIsLoading(false)
				// }
			}
		}
	};

	useEffect(() => {
		window.addEventListener('message', handleMessages)
		return () => {
			window.removeEventListener('message', handleMessages); // Clean up listener
		};
	}, [uploadedItems])
	return (
		<>
			{showModal ? (
				<ShowAiPriceModal show={showModal} onClose={handleShowModal} />
			): uploadedItems?.length ? <AiGradingItemsModal addMoreItems={handleShowModal} uploadedItems={{elements: uploadedItems, nrItems: 0, totalElementPrice: 0}} type={type} /> : null}
			

			<div className='w-full'>
				<p className='mb-2'>
					{message}
				</p>
				<Button
					className="text-black py-3 px-7 rounded-xl bg-gradient-to-br from-gradient-start to-gradient-end hover:from-gradient-end hover:to-gradient-start"
					onClickButton={handleShowModal}
					label={'Start Ai Grading'}
				/>
			</div>
		</>
	)
}

export default AIGrading

