import React, { useCallback, useState } from 'react';
import { useAppSelector } from '../../../../app/hooks';
import moment from 'moment';
import { classNames } from '../../../../utils/classNames';

const CallRules = () => {
	const {callSettings} = useAppSelector((state) => state?.aiAgentsCall);
	const [collapse, setCollapse] = useState<boolean>(false);

	const toggleCollapse = useCallback(()=> setCollapse(collapse => !collapse), [setCollapse]);
	return (
		<>
			<h3 className='font-dm_sansbold text-xl font-bold text-title flex flex-wrap'>
				Call Rules
				<button className='flex items-center text-primary-default text-sm font-normal ml-8' onClick={toggleCollapse} type='button'>
					Collapse
					<img alt='arrow down' width={20} className={classNames('ml-1 transition-all', collapse ? '' : 'rotate-180')} src='/svg/layout/arrow-down-colored.svg' />
				</button>
			</h3>
			<div className={classNames('flex flex-wrap md:-mx-2 my-3 transition-all', collapse ? 'h-0 overflow-hidden' : 'h-auto')}>
				<div className='w-full md:w-[20%] px-2 mb-2'>
					<div className='w-full h-full bg-white rounded-lg py-6 px-4'>
						<p className='text-agentDetails-secondaryDescription'>Brandname <b className='font-dm_sansbold ml-1'>{callSettings.brandName}</b></p>
					</div>
				</div>
				<div className='w-full md:w-[20%] px-2 mb-2'>
					<div className='w-full h-full bg-white rounded-lg py-6 px-4'>
						<p className='text-agentDetails-secondaryDescription'>Voice Message (1st Call) <b className='font-dm_sansbold ml-1'>{callSettings.leaveVoicemailFirstCall ? 'Yes' : 'No'}</b></p>
					</div>
				</div>
				<div className='w-full md:w-[20%] px-2 mb-2'>
					<div className='w-full h-full bg-white rounded-lg py-6 px-4'>
						<p className='text-agentDetails-secondaryDescription'>Repeat Calls  <b className='font-dm_sansbold ml-1'> {callSettings.maxAttempts} {callSettings.maxAttempts > 1 ? 'Times' : 'Time'}</b></p>
					</div>
				</div>
				<div className='w-full md:w-[20%] px-2 mb-2'>
					<div className='w-full h-full bg-white rounded-lg py-6 px-4'>
						<p className='text-agentDetails-secondaryDescription'>Voice message final attempt  <b className='font-dm_sansbold ml-1'> {callSettings.leaveVoicemailLastAttempt  ? 'Yes' : 'No'}</b></p>
					</div>
				</div>
				<div className='w-full md:w-[20%] px-2 mb-2'>
					<div className='w-full h-full bg-white rounded-lg py-6 px-4'>
						<p className='text-agentDetails-secondaryDescription'>Call time  <b className='font-dm_sansbold ml-1'>{moment(callSettings.preferredCallTime).format('DD/MM/YYYY HH:mm')}</b></p>
					</div>
				</div>
			</div>
		</>
	)
}

export default CallRules;