import React, { useEffect, useState } from 'react';
import Select from '../../../../shared/select';
import { useForm } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { tryToGetCallsInformation } from '../../../../store/brokers/ai-agents-call/aiAgentsCallSlice';
import { useGetCurrentUserQuery } from '../../../../store/user/userApi';

const BatchInformation = () => {
	const {data: user} = useGetCurrentUserQuery();
	const [batchIndex, setBatchIndex] = useState<number>(0);
	const {callsInformation, batches} = useAppSelector((state) => state?.aiAgentsCall);

	const BATCH_FILTERS = [...batches].map((batch, index) => ({label: batch._id + index, value: batch._id}))

	const {handleSubmit, register, watch} = useForm<{batchFilter: string}>();

	useEffect(()=>{
		[...BATCH_FILTERS].forEach((batch, index) => {
		 	if (batch.value === watch('batchFilter'))
		 		setBatchIndex(index)
		})
	}, [watch('batchFilter')])

	const onSubmit = () => {}

	const dispatch = useAppDispatch();


	useEffect(() => {
		if (batches && batches[batchIndex]?._id)
		dispatch(tryToGetCallsInformation({brokerId: user?.id || '', batchId: batches[batchIndex]._id}))
	}, [batches, batchIndex])

	return (
		<div className='my-5'>
			<h3 className='font-dm_sansbold text-xl font-bold text-title flex flex-wrap'>
				Batch Information
				<form className='flex flex-col md:flex-row font-dm_sansregular md:ml-auto pl-4' onSubmit={handleSubmit(onSubmit)}>
					<div className='text-sm mt-3'>Show: </div> 
					<Select register={register('batchFilter')} className=' text-sm font-normal mb-0 p-3 rounded-xl md:mx-4' options={[...BATCH_FILTERS]} />
					{/* <Button className='bg-gradient-to-br from-gradient-start to-gradient-end py-2 px-5 text-sm whitespace-nowrap h-11 rounded-lg' label='Filter' type='submit' /> */}
				</form>
			</h3>
			<div className='w-full md:w-11/12'>
				<div className='flex flex-wrap md:-mx-2 my-3'>
					{Object.keys(callsInformation[0]).map(information => (
						<div key={information} className='w-full md:w-3/12 px-2 mb-4'>
							<div className='w-full h-full bg-[#F7A21E0D] bg-opacity-5 rounded-lg p-4'>
								<div className='font-dm_sansbold text-agentDetails-secondaryDescription'>
									<p className='font-dm_sansbold w-full mb-2'>{information.replace(/([A-Z])/g, ' $1').charAt(0).toUpperCase() + information.replace(/([A-Z])/g, ' $1').slice(1)} </p>
									<div className='font-normal'>{callsInformation[0][information as 'averageCallTime']}</div>
								</div>
							</div>
						</div>
					))}
					
				</div>
			</div>
			
		</div>
	)
}

export default BatchInformation;