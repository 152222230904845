import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { aiAgentService } from './aiAgentsCallApi';
import { IBatches, ICallSettings, ICallStatusPaginated } from '../../../interfaces/ai-agents/agents-call/IAgentCall';
import { ICallResponse } from '../../../interfaces/ai-agents/IAgentCall';


const initialState: {
	itemsAreLoading: boolean,
	customerCalls: any,
	callsInformation: ICallResponse[],
	callSettings: ICallSettings,
	batches: IBatches[] | [],
}  = {
	itemsAreLoading: false,
	callSettings: {
		brandName: '',
		leaveVoicemailFirstCall: false,
		maxAttempts: 0,
		leaveVoicemailAfterRetry: false,
		leaveVoicemailLastAttempt: false,
		_id: '',
		preferredCallTime: '',
	},
	batches: [],
	callsInformation: [{
		averageCallTime: 0,
		confirmedNotShipping: 0,
		firstCallResponse: 0,
		intentToShip: 0,
		numberOfCustomerUploaded: 0,
		secondCallResponse: 0,
		thirdCallResponse: 0,
		totalCallsMade: 0,
		voiceMessagesLeft: 0
	}],
	customerCalls: []
};

export const tryToGetBatchInformations = createAsyncThunk<
{data: ICallStatusPaginated},
{ brokerId: string, status: string, batchId: string, pageNumber: number, pageSize: number }
>('aiAgentsCall/tryToGetBatchInformations', async (data) => {
const result = await aiAgentService.tryToGetBatchInformations(
	data
);
return result?.data;
});


export const tryToDownloadBatchPdf = createAsyncThunk<any, {callId: string, brokerId: string}>(
	'aiAgentsCall/tryToDownloadBatchPdf',
	async ({callId, brokerId}) => {
		const result = await aiAgentService.tryToDownloadBatchPdf({
			callId,
			brokerId
		});
		return result.data;
	},
);

export const tryToGetCallsInformation = createAsyncThunk<any, {batchId: string, brokerId: string}>(
	'aiAgentsCall/tryToGetCallsInformation',
	async ({batchId, brokerId}) => {
		const result = await aiAgentService.tryToGetCallsInformation({
			batchId,
			brokerId
		});
		return result.data;
	},
);

export const tryToGetBatches = createAsyncThunk<{data: IBatches[]}, {brokerId: string}>(
	'aiAgentsCall/tryToGetBatches',
	async ({brokerId}) => {
		const result = await aiAgentService.tryToGetBatches({
			brokerId
		});
		return result.data;
	},
);


export const aiAgentsCallSlice = createSlice({
	name: 'aiAgentsCall',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			// tryToGetBatchInformations
			.addCase(tryToGetBatchInformations.pending, (state, action) => {
				state.itemsAreLoading = true;
			})
			.addCase(tryToGetBatchInformations.rejected, (state) => {
				state.itemsAreLoading = false;
			})
			.addCase(tryToGetBatchInformations.fulfilled, (state, action) => {
				state.itemsAreLoading = false;
				state.customerCalls = action.payload.data
			})

			// tryToDownloadBatchPdf
			.addCase(tryToDownloadBatchPdf.pending, (state, action) => {
				state.itemsAreLoading = true;
			})
			.addCase(tryToDownloadBatchPdf.rejected, (state, action) => {
				state.itemsAreLoading = false;
			})
			.addCase(tryToDownloadBatchPdf.fulfilled, (state, action) => {
				state.itemsAreLoading = false;
			})

			// tryToGetCallsInformation
			.addCase(tryToGetCallsInformation.pending, (state, action) => {
				state.itemsAreLoading = true;
			})
			.addCase(tryToGetCallsInformation.rejected, (state, action) => {
				state.itemsAreLoading = false;
			})
			.addCase(tryToGetCallsInformation.fulfilled, (state, action) => {
				state.itemsAreLoading = false;
				state.callsInformation = action.payload.data
			})

			// tryToGetBatches
			.addCase(tryToGetBatches.pending, (state, action) => {
				state.itemsAreLoading = true;
			})
			.addCase(tryToGetBatches.rejected, (state, action) => {
				state.itemsAreLoading = false;
			})
			.addCase(tryToGetBatches.fulfilled, (state, action) => {
				state.itemsAreLoading = false;
				state.batches = action.payload.data || []
				state.callSettings = action.payload.data?.[0].callSettings
			})

	},
});

export default aiAgentsCallSlice.reducer;
