import { AxiosResponse } from 'axios';
import aiAgentAxios from '../../../utils/axios/aiAgent.axios';
import { IBatches, ICallStatusPaginated } from '../../../interfaces/ai-agents/agents-call/IAgentCall';

const tryToGetBatchInformations = (data: {
		brokerId: string,
		status: string
		batchId: string
}): Promise<AxiosResponse<{data: ICallStatusPaginated}>> => {
	return aiAgentAxios.put(`/ai-voice-calling/calls`, data)
}

const tryToDownloadBatchPdf = ({
	callId,
	brokerId
}: {
	callId?: string,
	brokerId: string
}): Promise<AxiosResponse<{data: string[][]}>> => {
return aiAgentAxios.put(callId ? `/ai-voice-calling/calls/download/${callId}` : `/ai-voice-calling/calls/download`, {callId, brokerId})
}

const tryToGetCallsInformation = (data: {
	brokerId: string,
	batchId: string
}): Promise<AxiosResponse<{data: ICallStatusPaginated}>> => {
return aiAgentAxios.put(`/ai-voice-calling/calls/information`, data)
}


const tryToGetBatches = (data: {
	brokerId: string,
}): Promise<AxiosResponse<{data: IBatches[]}>> => {
return aiAgentAxios.put(`/ai-voice-calling/batches`, data)
}

export const aiAgentService = {
	tryToGetBatchInformations,
	tryToDownloadBatchPdf,
	tryToGetCallsInformation,
	tryToGetBatches
};
