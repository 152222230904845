import React, { useEffect, useState } from 'react';
import AiGradingItemsModal from '../../../ai-agents/ai-grading/AiGradingItemsModal';
import { IUploadItems } from '../../../../interfaces/agents/IUploadItems';
import { AssetLibraryService } from '../../../../store/brokers/partners/asset-library/assetLibraryApi';

const BatchAssetBreakdown = ({
	id,
	clearId
}: {
	id: string,
	clearId: () => void
}) => {
	const [uploadedItems, setUploadedItems] = useState<{elements: IUploadItems[], totalElementPrice: number, nrItems: number}>({elements: [], totalElementPrice: 0, nrItems: 0})

	const getUploadItems = async () => {
		const response = await AssetLibraryService.tryToFetchBatchAssets(
			1,
			10,
			'',
			id
		);
		const data = response.data.data;
		setUploadedItems(data)
		// response.data.data.elements);
	}

	useEffect(()=>{
		getUploadItems();
	}, [id])

	console.log({id, uploadedItems})
	// const uploadedItems: IUploadItems[] = [];
	return (
		<AiGradingItemsModal uploadedItems={uploadedItems} clearSessionId={clearId} />
	)
}

export default BatchAssetBreakdown;