import React, { useEffect, useState } from 'react';
import { CSVLink } from 'react-csv';
import ExcelJS from 'exceljs';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { ASSET_STATUSES } from '../../../utils/constants/asset-statuses';
import {
    tryToFetchAssetFieldsTemplate,
    tryToFetchAssetTemplateFilters,
    tryToFetchSingleAssetTemplate
} from '../../../store/brokers/admin/asset-templates/assetsTemplatesSlice';
import { tryToDisableAsset, tryToFetchAssets } from '../../../store/brokers/admin/assets/assetsSlice';
import { tryToFetchAllPayServices } from '../../../store/brokers/admin/pay-services/payServicesSlice';
import { usePricingTemplateMutation } from '../../../store/brokers/partners/download-templates/downloadTemplatesApi';
import { IPaginationPayloadWithId } from '../../../interfaces/shared/IPaginationPayload';
import { IAssetFields } from '../../../interfaces/asset-templates/IAssetTemplate';
import { IPayServices } from '../../../interfaces/pay-services/IPayServices';
import { IToast } from '../../../interfaces/components/IToast';
import { IAssets } from '../../../interfaces/assets/IAssets';
import AssetExtendedModal from '../assets-extended/modals/AssetExtendedModal';
import ChangeBatchItemsStatusModal from './modals/ChangeBatchItemsStatusModal';
import ImportAssetsImagesModal from './modals/ImportAssetsImagesModal';
import ModalErrorChangeStatus from './modals/ModalErrorChangeStatus';
import ChangeItemStatusModal from './modals/ChangeItemStatusModal';
import ImportAssetsModal from './modals/ImportAssetsModal';
import AssetDetailsTable from './tables/AssetDetailsTable';
import AssetsFilters from './details/AssetsFilters';
import EmptyContainer from '../../../shared/empty-container';
import SelectCheckbox from '../../../shared/select-checkbox';
import DeleteModal from '../../../shared/delete-modal';
import Button from '../../../shared/button';
import Toast from '../../../shared/toast';
import DownloadSampleModal from './modals/DownloadSampleModal';


const AssetDetails = () => {
    const navigate = useNavigate()
    const dispatch = useAppDispatch();
    const csvLink = React.useRef() as React.MutableRefObject<any>;
    const csvLink2 = React.useRef() as React.MutableRefObject<any>;
    const { id } = useParams();
    const [rows, setRows] = useState<IAssets[]>([]);
    const [assetFields, setAssetFields] = useState<IAssetFields[]>([]);
    const [pageAccess, setPageAccess] = useState<any>();
    const [assetItem, setAssetItem] = useState<string>();
    const [services, setServices] = useState<any>();
    const [filterValues, setFilterValues] = useState<any>();
    const [showToast, setShowToast] = useState<IToast | null>();
    const [selectedStatus, setSelectedStatus] = useState<any>();
    const [selectedRows, setSelectedRows] = useState<Array<string>>();
    const [templateFilters, setTemplateFilters] = useState<Array<any>>();
    const [loadingDownload, setLoadingDownload] = useState<boolean>(false);
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
    const [showErrorStatusModal, setShowErrorStatusModal] = useState<boolean>(false);
    const [showAssetExtended, setShowAssetExtended] = useState<{ show: boolean, asset: string }>()
    const [assetsToDownload, setAssetsToDownload] = useState<Array<any>>();
    const [selectedServices, setSelectedServices] = useState<any>();
    const [assetTemplateSample, setAssetTemplateSample] = useState<Array<any>>();
    const [showImportAssetsModal, setShowImportAssetsModal] = useState<boolean>(false);
    const [loadingDownloadAssets, setLoadingDownloadAssets] = useState<boolean>(false);
    const [showDownloadModal, setShowDownloadModal] = useState<{ show: boolean, type: string }>();
    const [showImportAssetsImagesModal, setShowImportAssetsImagesModal] = useState<boolean>(false)
    const [showChangeBulkStatusesModal, setShowChangeBulkStatusesModal] = useState<boolean>(false);
    const [showItemChangeStatusModal, setShowItemChangeStatusModal] = useState<{ show: boolean, assetId: string, services: any, status: string, extendedExist?: any }>();
    const [paginationState, setPaginationState] = useState<IPaginationPayloadWithId>({
        pageNumber: 1,
        pageSize: 10,
        id: id || ''
    });
    const accessControl = useAppSelector((state) => state?.partners?.partnerNavigation);
    const paginationData = useAppSelector((state) => state.assets?.assets?.data?.page);
    const rowsLoading = useAppSelector((state) => state.assets?.assetsAreLoading);
    const stateAssetFields = useAppSelector((state) => state.assetsTemplates);
    const state = useAppSelector((state) => state.assets);
    const [pricingTemplateMutation] = usePricingTemplateMutation();

    useEffect(() => {
        const findPage = accessControl && accessControl?.length > 0 && accessControl?.find((item: any) => item?.name === 'Assets')
        const findChild = findPage && findPage?.children && findPage?.children?.length > 0 && findPage?.children?.find((item: any) => item?.name === 'Asset Details')
        setPageAccess(findChild || undefined)
    }, [accessControl])

    const getPayServicesData = async () => {
        const payServicesResponse = await dispatch(tryToFetchAllPayServices()).unwrap()
        const formatPayServices = (payServicesResponse && payServicesResponse?.data && payServicesResponse?.data?.length > 0)
            && payServicesResponse?.data?.map((service: IPayServices) => ({ ...service, label: service?.displayName, value: service?._id }))
        setServices(formatPayServices || [])
    }

    const onResetPaginationSettings = () => {
        setPaginationState({
            pageNumber: 1,
            pageSize: paginationState?.pageSize || 10,
            id: id || ''
        })
    }

    useEffect(() => {
        if (state.assets) {
            const rowsFormat =
                state.assets?.data?.elements && Array.isArray(state.assets?.data.elements)
                    ? state.assets?.data.elements.map((r) => ({ ...r, id: r._id }))
                    : [];
            if (JSON.stringify(rowsFormat) !== JSON.stringify(rows)) {
                setRows(rowsFormat);
            }
        }
    }, [state.assets]);

    useEffect(() => {
        if (stateAssetFields?.assetTemplateFilters) {
            setTemplateFilters(stateAssetFields?.assetTemplateFilters)
        }
    }, [stateAssetFields?.assetTemplateFilters]);

    useEffect(() => {
        if (stateAssetFields.assetTemplateFields) {
            const rows =
                stateAssetFields.assetTemplateFields && Array.isArray(stateAssetFields.assetTemplateFields)
                    ? stateAssetFields.assetTemplateFields?.filter((r) => r?.enabled).map((r) => ({ ...r, id: r.id }))
                    : [];
            if (JSON.stringify(rows) !== JSON.stringify(assetFields)) {
                setAssetFields(rows);
            }
        }
    }, [stateAssetFields.assetTemplateFields]);

    const onGettingAssetTemplateItemsData = async () => {
        let formatData: any = {
            ...paginationState || {},
        }
        const filters = filterValues && Object.keys(filterValues)
        let formatFilters
        const filtersValuesFormat = filters && filters?.length > 0 && filters?.map((item: any) => {
            return {
                key: item,
                value: Object.keys(filterValues) ? filterValues?.[item] || undefined : undefined
            }
        })
        if (filtersValuesFormat && filtersValuesFormat?.length > 0) {
            formatFilters = {
                value: filtersValuesFormat?.filter((key: any) => key?.value)
            }
        }
        if (selectedStatus) {
            formatData = {
                ...formatData || {},
                data: {
                    status: selectedStatus?.value
                }
            }
        }
        if (selectedServices && selectedServices?.length > 0) {
            formatData = {
                ...formatData || {},
                data: {
                    ...formatData?.data || {},
                    hiddenServicesIds: selectedServices?.map((item: any) => item?.value)
                }
            }
        }
        if (formatFilters && formatFilters?.value) {
            formatData = {
                ...formatData || {},
                data: {
                    ...formatData?.data || {},
                    value: formatFilters?.value || []
                }
            }
        }
        await dispatch(tryToFetchAssets(formatData));
    }

    useEffect(() => {
        if (id && paginationState) {
            onGettingAssetTemplateItemsData()
        }
    }, [paginationState, id]);

    useEffect(() => {
        if (id) {
            getPayServicesData();
            dispatch(tryToFetchAssetFieldsTemplate(id));
            dispatch(tryToFetchSingleAssetTemplate(id));
            dispatch(tryToFetchAssetTemplateFilters({ id: id }))
        }
    }, [id]);

    const onAddNewAsset = () => {
        navigate(`/assets/create/${id}`);
    }

    const onCloseDeleteModal = () => {
        setShowDeleteModal(false)
    }

    const onDeleteAsset = async () => {
        try {
            const data = {
                assetTemplateId: id || '',
                assetId: assetItem || ''
            }
            await dispatch(tryToDisableAsset(data))
            setShowToast({ type: 'success', message: 'Asset successfully deleted' });
        } catch (error) {
            setShowToast({ type: 'error', message: `${error}` });
        }
        setAssetItem(undefined)
        setShowDeleteModal(false)
    }

    const onDeleteAssetRequest = (id: string) => {
        setShowDeleteModal(true)
        setAssetItem(id)
    }

    const onToggleImportAssetsModal = () => {
        setShowImportAssetsModal(!showImportAssetsModal)
    }

    const onToggleImportAssetsImagesModal = () => {
        setShowImportAssetsImagesModal(!showImportAssetsImagesModal)
    }

    const onCloseDownloadModal = () => {
        setShowDownloadModal(undefined)
        document.body.style.overflow = 'auto';
    }

    const columnNumberToLetter = (colNum: number) => {
        let letter = '';
        while (colNum > 0) {
            const remainder = (colNum - 1) % 26;
            letter = String.fromCharCode(65 + remainder) + letter;
            colNum = Math.floor((colNum - 1) / 26);
        }
        return letter;
    };

    const formatString = (item: string) => {
        return item
            .replace(/_/g, ' ')
            .toLowerCase()
            .split(' ')
            .map((word: any) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    }

    const downloadExcel = async (data?: any) => {
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Styled Sheet');

        const columnCount = data?.[0]?.length > 0 ? data?.[0]?.length : 1
        for (let i = 1; i <= columnCount; i++) {
            worksheet.getColumn(i).width = 13;
            const cell = worksheet.getCell(`${columnNumberToLetter(i)}1`);
            cell.value = data?.[0]?.[i - 1] ? formatString(data?.[0]?.[i - 1]) : data?.[0]?.[i - 1];
            cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: '202020' },
            };
            cell.border = {
                left: { style: 'thin', color: { argb: 'FFFFFFFF' } }
            };
            cell.alignment = {
                vertical: 'middle',
                horizontal: 'center'
            };
            cell.font = {
                color: { argb: 'FFFFFF' },
                name: 'Avenir',
                bold: true,
                size: 12
            };
        }
        data?.slice(1).forEach((row: any, rowIndex: number) => {
            row?.forEach((cellValue: any, colIndex: number) => {
                const cell = worksheet.getCell(`${columnNumberToLetter(colIndex + 1)}${rowIndex + 2}`);
                cell.value = cellValue;
                cell.alignment = { vertical: 'middle', horizontal: 'center' };
            });
        });
        workbook.xlsx.writeBuffer().then((buffer: any) => {
            const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.download = `Pricing_catalog.xlsx`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(url);
        });
        setLoadingDownload(false)
        setLoadingDownloadAssets(false)
        onCloseDownloadModal()
    };

    const onDownloadTemplate = async (excel: boolean) => {
        if (loadingDownload) {
            return;
        }
        setLoadingDownload(true)
        if (!id || id === '') {
            setShowToast({ type: 'error', message: 'Asset must be specified to download sample.' })
            return;
        }
        try {
            const response = await pricingTemplateMutation({ assetTemplateId: id || '', status: true }).unwrap()
            if (excel) {
                downloadExcel();
            } else {
                setAssetTemplateSample(response || [])
            }
        } catch (err) {
            setShowToast({ type: 'error', message: `${err}` })
        }
    }

    useEffect(() => {
        if (assetTemplateSample && loadingDownload) {
            csvLink.current.link.click()
            onCloseDownloadModal()
        }
        setLoadingDownload(false)
    }, [assetTemplateSample])

    const onDownloadAssets = async (excel: boolean) => {
        if (loadingDownloadAssets) {
            return;
        }
        setLoadingDownloadAssets(true)
        if (!id || id === '') {
            setShowToast({ type: 'error', message: 'Asset must be specified to download sample.' })
            return;
        }
        try {
            const response = await pricingTemplateMutation({ assetTemplateId: id || '', status: true }).unwrap()
            if (excel) {
                downloadExcel();
            } else {
                setAssetsToDownload(response || [])
            }
        } catch (err) {
            setShowToast({ type: 'error', message: `${err}` })
            setLoadingDownloadAssets(false)
        }
    }

    useEffect(() => {
        if (assetsToDownload && loadingDownloadAssets) {
            csvLink2.current.link.click()
        }
        setLoadingDownloadAssets(false)
    }, [assetsToDownload])

    const onImportAssetsSuccessfully = () => {
        setPaginationState({
            ...paginationState || {},
            pageNumber: 1,
        })
        onGettingAssetTemplateItemsData()
    }

    const onEditAsset = async (assetId: string) => {
        navigate(`/assets/${id}/edit/${assetId}`)
    }

    const onChangeStatusSelected = (value: any, type?: string) => {
        setSelectedStatus(value)
        onResetPaginationSettings()
    }

    const onClearAllFilters = () => {
        setSelectedStatus(undefined)
        setFilterValues(undefined)
        onResetPaginationSettings()
    }

    const onRequestToChangeItemStatus = (assetId: string, status: string, services: any, extendedExist: boolean) => {
        if (['phones', 'laptops', 'tablets']?.includes(stateAssetFields?.assetTemplateDetails?.name || '')) {
            setShowItemChangeStatusModal({
                show: true,
                assetId,
                services: services,
                status,
                extendedExist: extendedExist,
            })
        } else {
            setShowItemChangeStatusModal({
                show: true,
                assetId,
                services: services,
                status
            })
        }
        document.body.style.overflow = 'hidden';
    }

    const onSaveSuccessfully = (message: string) => {
        setShowToast({
            type: 'success',
            message
        })
        setSelectedRows(undefined)
    }

    const onClickSelectRow = (id?: string) => {
        if (!id) {
            if ((selectedRows?.length === rows?.length && paginationState?.pageNumber === 1) || (paginationState?.pageNumber > 1 && selectedRows?.length === (paginationState?.pageSize * paginationState?.pageNumber))) {
                setSelectedRows(undefined)
            } else {
                const getAllIds = rows?.length > 0 && rows?.map((row) => row?._id || '')
                const mergeSelected = [...selectedRows || [], ...getAllIds || []]
                const uniqueIds = mergeSelected.filter((v, i, a) => a.indexOf(v) === i);
                setSelectedRows(uniqueIds)
            }
        }
        else {
            const findIfAlreadySelected = selectedRows && selectedRows?.length > 0 && selectedRows?.find((row) => row === id)
            if (findIfAlreadySelected) {
                const filterOthers = selectedRows && selectedRows?.length > 0 && selectedRows?.filter((row) => row !== id)
                setSelectedRows(filterOthers || undefined)
            } else {
                const formatNew = [
                    ...selectedRows || [],
                    id || ''
                ]
                setSelectedRows(formatNew || undefined)
            }
        }
    }

    const onChangeBulkStatuses = () => {
        setShowChangeBulkStatusesModal(!showChangeBulkStatusesModal)
    }

    const onChangeSelectedFilterValue = (value: any, type?: string) => {
        if (type) {
            const findField = templateFilters?.find((field) => field?.name === type);
            setFilterValues({
                ...(filterValues || {}),
                [type]: findField?.dataType === 'boolean' ? value?.value === 'true' : value?.value || undefined,
            });
            onResetPaginationSettings()
        }
    };

    const onChangeFilterInput = (value: string | null | undefined, type: string) => {
        const findField = templateFilters?.find((field) => field?.name === type);
        setFilterValues({
            ...(filterValues || {}),
            [type]:
                findField?.dataType === 'number' ? Number(value || 0) : value,
        });
        onResetPaginationSettings()
    };

    const onClickAddAssetsExtendedModal = (name: string) => {
        setShowAssetExtended({
            show: true,
            asset: name
        })
    }

    const onSaveSuccessfullyExteneded = (type: string, message: string) => {
        setShowToast({
            type: type,
            message
        })
    }

    const onCloseStatusModal = () => {
        document.body.style.overflow = 'auto';
        setShowItemChangeStatusModal(undefined)
    }

    const onChangeServiceSelected = (e: any, type?: string) => {
        setSelectedServices(e)
        onResetPaginationSettings()
    }

    const onClickShowDownloadModal = (type: string) => {
        setShowDownloadModal({
            show: true,
            type: type,
        })
        document.body.style.overflow = 'hidden';
    }

    return (
        <div>
            <div>
                <p className='page-title'>Asset Details</p>
                <p className='mt-3 font-medium'>Asset Catalog: {stateAssetFields?.assetTemplateDetails?.displayName}</p>
            </div>
            <div className='grid grid-cols-2 lg:grid-cols-4 xl:grid-cols-5 gap-4 mt-7 mb-4'>
                <SelectCheckbox
                    name='Status'
                    placeholder=' '
                    selectedOption={selectedStatus}
                    options={ASSET_STATUSES}
                    onChangeSelectedOption={onChangeStatusSelected}
                />
                <SelectCheckbox
                    name='Hidden In Services'
                    placeholder=' '
                    multiple={true}
                    selectedOption={selectedServices}
                    options={services}
                    onChangeSelectedOption={onChangeServiceSelected}
                />
                {(templateFilters && templateFilters?.length > 0) &&
                    <AssetsFilters
                        items={templateFilters || []}
                        filterValues={filterValues || null}
                        onChangeInput={onChangeFilterInput}
                        onChangeSelectedValue={onChangeSelectedFilterValue}
                    />
                }
            </div>
            <div className='flex flex-row justify-end flex-wrap mt-6'>
                <Button
                    label={'Clear All Filters'}
                    onClickButton={onClearAllFilters}
                    className={'btn-primary-text-underline mb-1'}
                    dataQa={'clear-asset-filters'}
                />
                <Button
                    label={'Download Assets'}
                    onClickButton={() => onClickShowDownloadModal('assets')}
                    className={'btn-main mr-2 mb-1 !py-2 !shadow-none'}
                    dataQa={'download-asset-btn'}
                />
                <CSVLink
                    ref={csvLink2}
                    data={assetsToDownload?.filter((item, idx) => idx > 0) || []}
                    headers={assetsToDownload?.[0] || []}
                    className={'hidden'}
                    target={'_blank'}
                    filename={'Assets-data.csv'}
                >
                    {loadingDownload ? 'Loading data...' : 'Download Sample'}
                </CSVLink>
                <Button
                    label='Import Assets'
                    className={`'!rounded ${(!accessControl || pageAccess?.actions?.['Import Assets']) ? 'btn-main' : 'btn-main-disable'} min-w-[150px] mb-1 !rounded !shadow-none mr-2`}
                    onClickButton={() => (!accessControl || pageAccess?.actions?.['Import Assets']) && onToggleImportAssetsModal()}
                    dataQa={'import-asset-btn'}
                />
                <Button
                    label='Import Assets Images'
                    className={`'!rounded ${(!accessControl || pageAccess?.actions?.['Import Assets Images']) ? 'btn-main' : 'btn-main-disable'} min-w-[150px] mb-1 !rounded !shadow-none mr-2`}
                    onClickButton={() => (!accessControl || pageAccess?.actions?.['Import Assets Images']) && onToggleImportAssetsImagesModal()}
                    dataQa={'import-asset-btn'}
                />
                <Button
                    label={'Download Sample'}
                    onClickButton={() => onClickShowDownloadModal('sample')}
                    className={'btn-main mr-2 !py-2 mb-1 !shadow-none'}
                    dataQa={'download-sample-asset-btn'}
                />
                <CSVLink
                    ref={csvLink}
                    data={[]}
                    headers={assetTemplateSample?.[0] || []}
                    className={'hidden'}
                    target={'_blank'}
                    // asyncOnClick={true}
                    filename={'Asset-catalog-sample.csv'}
                >
                    {loadingDownload ? 'Loading data...' : 'Download Sample'}
                </CSVLink>
                <Button
                    label={'Add New Asset'}
                    className={`!py-2 ${(!accessControl || pageAccess?.actions?.['Add New Asset']) ? 'btn-main' : 'btn-main-disable'} !shadow-none mb-1`}
                    onClickButton={() => (!accessControl || pageAccess?.actions?.['Add New Asset']) && onAddNewAsset()}
                    dataQa={'add-new-asset-btn'}
                />
            </div>
            <div className='my-5 relative'>
                {(selectedRows && selectedRows?.length > 0) &&
                    <div className={'min-w-full py-2 px-2 flex flex-row justify-between items-center bg-[#F1F9F7]'}>
                        <p className='font-bold'>{selectedRows ? selectedRows?.length : 0} Rows selected</p>
                        <Button
                            label={'Change Selected Assets Status'}
                            dataQa={'change-selected-assets-status'}
                            onClickButton={() => (!accessControl || pageAccess?.actions?.['Change Selected Assets Status']) && onChangeBulkStatuses()}
                            className={(!accessControl || pageAccess?.actions?.['Change Selected Assets Status']) ? 'btn-main my-2' : 'btn-main-disable my-2'}
                        />
                    </div>
                }
                {(rows?.length > 0 || rowsLoading) ?
                    <AssetDetailsTable
                        rows={rows}
                        rowsLoading={rowsLoading}
                        assetTemplateFields={assetFields}
                        paginationData={paginationData}
                        paginationState={paginationState}
                        accessControl={accessControl}
                        actions={pageAccess?.actions}
                        selectedRows={selectedRows}
                        showExtended={['phones', 'laptops', 'tablets']?.includes(stateAssetFields?.assetTemplateDetails?.name || '')}
                        onClickSelectRow={onClickSelectRow}
                        setPaginationState={setPaginationState}
                        onDeleteAssetRequest={onDeleteAssetRequest}
                        onChangeAssetStatus={onRequestToChangeItemStatus}
                        onClickAddAssetsExtendedModal={onClickAddAssetsExtendedModal}
                        onEditAsset={onEditAsset}
                    />
                    : <EmptyContainer text={'No asset data added yet.'} showImage={true} />
                }
            </div>
            <DeleteModal
                openDeleteModal={showDeleteModal}
                handleCloseDeleteModal={onCloseDeleteModal}
                onApproveDeletion={onDeleteAsset}
            />
            {showToast?.message &&
                <Toast
                    type={showToast?.type}
                    text={showToast?.message}
                    onHandleCloseToast={() => setShowToast(null)}
                />
            }
            {showItemChangeStatusModal?.show &&
                <ChangeItemStatusModal
                    openModal={showItemChangeStatusModal?.show}
                    assetTemplateId={id || ''}
                    hiddenServices={showItemChangeStatusModal?.services}
                    extendedExist={showItemChangeStatusModal?.extendedExist}
                    assetId={showItemChangeStatusModal?.assetId || ''}
                    actualStatus={showItemChangeStatusModal?.status || ''}
                    handleCloseModal={onCloseStatusModal}
                    onSaveSuccessfully={onSaveSuccessfully}
                />
            }
            {showChangeBulkStatusesModal &&
                <ChangeBatchItemsStatusModal
                    openModal={showChangeBulkStatusesModal}
                    assetTemplateId={id || ''}
                    assetIds={selectedRows || []}
                    services={services}
                    handleCloseModal={onChangeBulkStatuses}
                    onSaveSuccessfully={onSaveSuccessfully}
                />
            }
            <ImportAssetsModal
                openImportModal={showImportAssetsModal}
                handleCloseModal={onToggleImportAssetsModal}
                onImportAssetsSuccessfully={onImportAssetsSuccessfully}
                assetTemplateId={id || ''}
            />
            {showAssetExtended?.show &&
                <AssetExtendedModal
                    openCreateAssetModal={showAssetExtended?.show}
                    name={showAssetExtended?.asset}
                    handleCloseAssetModal={() => setShowAssetExtended(undefined)}
                    onSaveChanges={onSaveSuccessfullyExteneded}
                />
            }
            {showErrorStatusModal &&
                <ModalErrorChangeStatus
                    openModal={showErrorStatusModal}
                    handleCloseModal={() => setShowErrorStatusModal(false)}
                />
            }
            {showImportAssetsImagesModal &&
                <ImportAssetsImagesModal
                    openImportModal={showImportAssetsImagesModal}
                    handleCloseModal={onToggleImportAssetsImagesModal}
                    onImportAssetsSuccessfully={onImportAssetsSuccessfully}
                    assetTemplateId={id || ''}
                />
            }
            {showDownloadModal?.show &&
                <DownloadSampleModal
                    openModal={showDownloadModal?.show}
                    type={showDownloadModal?.type || ''}
                    loadingDownload={showDownloadModal?.type === 'sample' ? loadingDownload : loadingDownloadAssets}
                    actions={pageAccess?.actions}
                    accessControl={accessControl}
                    onDownloadAssets={onDownloadAssets}
                    onDownloadTemplate={onDownloadTemplate}
                    handleClose={onCloseDownloadModal}
                />
            }
        </div>
    )
}

export default AssetDetails;
