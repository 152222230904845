import React, { useState, useCallback, useEffect } from 'react';
import Papa from 'papaparse';
import * as XLSX from 'xlsx';
import { useDropzone } from 'react-dropzone';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { IMarket } from '../../../../interfaces/markets/IMarket';
import { useGetAllMarketsMutation } from '../../../../store/user/userDomApi';
import { tryToUploadPricingCSV } from '../../../../store/brokers/partners/pricing/pricingSlice';
import { tryToValidatedImportedItems } from '../../../../store/brokers/partners/purchase-orders/purchaseOrdersSlice';
import { tryToFetchAllCurrencies } from '../../../../store/brokers/shared-endpoints/wholesale-price/wholesalePriceSlice';
import { tryToFetchCategoryMappingsWithoutPagination } from '../../../../store/brokers/shared-endpoints/category-mappings/categoryMappingsSlice';
import SuccessfullyUploadFile from '../details/SuccessfullyUploadedFile';
import UploadCsvFile from '../details/UploadCsvFile';
import Modal from '../../../../shared/modal';


interface IImportPricingCsvModal {
    openImportModal: boolean;
    sectors: Array<any>;
    partnerId: string;
    marketSelected?: string;
    handleCloseModal: () => void;
}

const ImportPricingCsvModal = ({
    openImportModal,
    sectors,
    partnerId,
    handleCloseModal,
}: IImportPricingCsvModal) => {
    const dispatch = useAppDispatch();
    const [importError, setImportError] = useState<string>()
    const [importedSuccessfully, setImportedSuccessfully] = useState<boolean>(false)
    const [selectedSector, setSelectedSector] = useState<any>()
    const [fileSelected, setFileSelected] = useState<any>()
    const [importLoading, setImportLoading] = useState<boolean>(false)
    const [showMarkets, setShowMarkets] = useState<boolean>(false)
    const [markets, setMarkets] = useState<any>()
    const [templates, setTemplates] = useState<any>()
    const [allCurrencies, setAllCurrencies] = useState<any>()
    const [selectedMarket, setSelectedMarket] = useState<any>()
    const [selectedTemplate, setSelectedTemplate] = useState<any>()
    const [selectedCurrency, setSelectedCurrency] = useState<any>()
    const [rowsError, setRowsError] = useState<any>()
    const [successRows, setSuccessRows] = useState<any>();
    const [showReplacements, setShowReplacements] = useState<{ show: boolean, data?: any }>();
    const currencies = useAppSelector((state: any) => state?.wholesale?.wholesaleCurrencies);
    const templateState = useAppSelector((state) => state.categoryMapping);
    const [getAllMarkets] = useGetAllMarketsMutation()

    const onDrop = useCallback((acceptedFiles: any) => {
        setFileSelected(acceptedFiles?.[0])
    }, [])

    useEffect(() => {
        if (selectedSector) {
            const filtersFormat = {
                assetTemplateId: selectedSector?.value,
                enabled: true,
                status: 'COMPLETED',
                pricingType: 'PRICING'
            }
            dispatch(tryToFetchCategoryMappingsWithoutPagination(filtersFormat))
        }
    }, [selectedSector])

    useEffect(() => {
        if (templateState?.categoryMappings) {
            const formatTemplates = templateState?.categoryMappings && templateState?.categoryMappings?.length > 0 && templateState?.categoryMappings?.map((item: any) => ({ label: `${item?.name}${item?.broker?.companyName ? ' - ' + item?.broker?.companyName : ''}`, value: item?._id || item?.id, name: item?.name }))
            setTemplates(formatTemplates || undefined)
        }
    }, [templateState?.categoryMappings])

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

    useEffect(() => {
        if (currencies) {
            const formatCurrencies = currencies && currencies?.length > 0 && currencies?.map((currency: any) => ({ label: currency?.descriptor?.name, value: currency?._id, name: currency?.descriptor?.name }))
            setAllCurrencies(formatCurrencies || undefined)
        }
    }, [currencies])

    const onGettingAllMarkets = async () => {
        const marketResponse = await getAllMarkets(null).unwrap()
        const formatMarkets = marketResponse && marketResponse?.length > 0 && marketResponse?.map((market: IMarket) => ({ ...market, name: market?.label, label: market?.label, value: market?._id }))
        setMarkets(formatMarkets || [])
    }

    const onCloseModal = () => {
        handleCloseModal();
        setImportError(undefined)
        setImportedSuccessfully(false)
        setFileSelected(undefined)
        setSelectedSector(undefined)
        setSelectedCurrency(undefined)
        setSelectedMarket(undefined)
        setSuccessRows(undefined)
        setRowsError(undefined)
        setShowReplacements(undefined)
        setShowMarkets(false)
    }

    const parseData = (assets: any) => {
        const headers = assets?.[0];
        const keys = Object.values(headers).map((header: any) => (header || '')?.toLowerCase());
        const result = assets?.slice(1)
            .filter((asset: any) => Object.keys(asset).length > 0)
            .map((asset: any) => {
                return keys?.reduce((obj, key, idx) => {
                    if (key === 'price') {
                        // eslint-disable-next-line
                        obj.quantity = asset[`Column${idx + 1}`] || 0
                    } else if (typeof asset[`Column${idx + 1}`] === 'string') {
                        const trimmedValue = asset[`Column${idx + 1}`]?.trim();
                        if (trimmedValue && key !== 'price' && /^\d+(\s?\d+)*\s?-Wing$/.test(trimmedValue)) {
                            const formatElement = trimmedValue?.replace('-Wing', '')
                            obj[key] = formatElement
                        } else {
                            obj[key] = trimmedValue;
                        }
                    } else if (asset[`Column${idx + 1}`] !== null && asset[`Column${idx + 1}`] !== undefined) {
                        if (asset?.[`Column${idx + 1}`] && key !== 'price' && /^\d+(\s?\d+)*\s?-Wing$/.test(asset?.[`Column${idx + 1}`])) {
                            const formatElement = asset[`Column${idx + 1}`]?.replace('-Wing', '')
                            obj[key] = formatElement
                        } else {
                            obj[key] = asset[`Column${idx + 1}`] || undefined;
                        }
                    }
                    return obj;
                }, {});
            });
        return result;
    };

    const onUploadFile = async () => {
        if (!selectedSector || (showMarkets && !selectedMarket) || (!showMarkets && !selectedCurrency)) {
            setImportError('Please select sector and currency or market first to continue with upload')
            return;
        }
        setImportLoading(true)
        try {
            const errorInRows: any = []
            let formatResults: any = []
            const fileExtension = (fileSelected.name.split('.').pop() || '').toLowerCase();
            if (fileExtension === 'csv') {
                await new Promise<void>((resolve) => {
                    Papa.parse(fileSelected, {
                        header: true,
                        skipEmptyLines: true,
                        complete: async function (results: any) {
                            formatResults = results?.data && results?.data?.length > 0 && results?.data?.map((item: any, index: number) => {
                                const asset = Object.assign(item)
                                // eslint-disable-next-line
                                const quantity = item?.price ? Number(item?.price?.replace(/[^0-9\.]+/g, '')) : 0
                                delete asset.price
                                const objectKeysFormat = asset && Object.keys(asset)
                                objectKeysFormat.forEach((element: any) => {
                                    if (asset?.[element] && element !== 'price' && /^\d+(\s?\d+)*\s?-Wing$/.test(asset?.[element])) {
                                        const formatElement = asset?.[element]?.replace('-Wing', '')
                                        asset[element] = formatElement
                                    }
                                });
                                return {
                                    ...asset || {},
                                    index: index + 2,
                                    quantity
                                }
                            })
                            resolve();
                        }
                    });
                })
            } else if (fileExtension === 'xlsx' || fileExtension === 'xls') {
                await new Promise<void>((resolve, reject) => {
                    const reader = new FileReader();
                    reader.onload = async (e: any) => {
                        try {
                            const data = new Uint8Array(e?.target?.result);
                            const workbook = XLSX.read(data, { type: 'array' });
                            const sheetName = workbook.SheetNames[0];
                            const sheet = workbook.Sheets[sheetName];
                            const mergedRanges = sheet['!merges'] || [];
                            const rawExcelData: any = XLSX.utils.sheet_to_json(sheet, { header: 1 });
                            const excelData = rawExcelData.map((row: any, rowIndex: number) => {
                                const isMergedRow = mergedRanges.some((merge) =>
                                    rowIndex >= merge.s.r && rowIndex <= merge.e.r
                                );
                                if (isMergedRow) return null;
                                const processedRow = row?.reduce((acc: any, cell: any, index: any) => {
                                    const columnKey = `Column${index + 1}`;
                                    acc[columnKey] = cell !== '' && cell != null ? cell : undefined;
                                    return acc;
                                }, {});
                                console.log(row, 'row', rowIndex)
                                const colName = `Column${((rawExcelData?.[0]?.length || 0) + 1)}`
                                if (rowIndex === 0) {
                                    return { ...processedRow || {}, [colName]: 'index' }
                                } else if (row !== null && row && row?.length > 0) {
                                    return { ...processedRow || {}, [colName]: rowIndex + 1 };
                                } else {
                                    return processedRow
                                }
                            }).filter((row: any) => row !== null);
                            console.log(excelData, 'here')
                            formatResults = parseData(excelData || []);
                            setImportError(undefined);
                            resolve();
                        } catch (error) {
                            reject(error);
                        }
                    }
                    reader.onerror = (error) => reject(error);
                    reader.readAsArrayBuffer(fileSelected);
                });
            } else {
                setImportError('Unsupported file format. Please upload a CSV or Excel file.');
                setImportLoading(false)
                return;
            }
            const formatDataSuccess: any = []
            if (formatResults) {
                const formData = {
                    assetTemplateId: selectedSector?.value,
                    data: formatResults || []
                }
                const response = await dispatch(tryToValidatedImportedItems(formData)).unwrap();
                response?.data && response?.data?.length > 0 && response?.data?.map((item: any) => {
                    if (item?.succeed) {
                        const objectToAdd = {
                            asset: {
                                ...item || {},
                                price: undefined,
                                quantity: undefined,
                                index: undefined,
                                succeed: undefined,
                            },
                            quantity: Number(item?.quantity || item?.price || 0),
                        }
                        delete objectToAdd.asset.price
                        delete objectToAdd.asset.index
                        delete objectToAdd.asset.succeed
                        delete objectToAdd.asset.quantity
                        formatDataSuccess.push(objectToAdd)
                    } else {
                        errorInRows.push(item)
                    }
                    return item
                })
                if (response?.replacements && response?.replacements?.length > 0) {
                    setShowReplacements({
                        show: true,
                        data: response?.replacements
                    })
                    setSuccessRows(formatDataSuccess)
                }
                if (!(errorInRows && errorInRows?.length > 0)) {
                    setImportError(undefined)
                    setFileSelected(undefined)
                    if (!(response?.replacements && response?.replacements?.length > 0)) {
                        setImportedSuccessfully(true)
                    }
                    setRowsError(undefined)
                } else {
                    setImportError(undefined)
                    setRowsError(errorInRows)
                }
                if ((response?.replacements && response?.replacements?.length > 0) || (errorInRows && errorInRows?.length > 0)) {
                    setImportLoading(false)
                    return;
                }
            } else {
                setImportError('File is empty')
                setImportLoading(false)
                return;
            }
            let payload: any = {
                templateId: selectedSector?.value || '',
                data: formatDataSuccess?.length > 0 ? formatDataSuccess : formatResults || [],
                brokerId: partnerId || ''
            }
            if (selectedCurrency && !showMarkets) {
                payload = {
                    ...payload || {},
                    currency: selectedCurrency?.value || ''
                }
            }
            if (showMarkets && selectedMarket) {
                payload = {
                    ...payload || {},
                    marketId: selectedMarket?.value || ''
                }
            }
            if (selectedTemplate) {
                payload = {
                    ...payload || {},
                    template: selectedTemplate?.name || ''
                }
            }
            await dispatch(tryToUploadPricingCSV(payload)).unwrap()
            setImportError(undefined)
            setImportedSuccessfully(true)
            setSelectedSector(undefined)
            setShowMarkets(false)
        } catch (err) {
            setImportedSuccessfully(false)
            setImportError(`${err}`)
        }
        setImportLoading(false)
    }

    const onAcceptChanges = async () => {
        setImportLoading(true)
        try {
            let payload: any = {
                templateId: selectedSector?.value || '',
                data: successRows || [],
                brokerId: partnerId || ''
            }
            if (selectedCurrency && !showMarkets) {
                payload = {
                    ...payload || {},
                    currency: selectedCurrency?.value || ''
                }
            }
            if (showMarkets && selectedMarket) {
                payload = {
                    ...payload || {},
                    marketId: selectedMarket?.value || ''
                }
            }
            if (selectedTemplate) {
                payload = {
                    ...payload || {},
                    template: selectedTemplate?.name || ''
                }
            }
            await dispatch(tryToUploadPricingCSV(payload)).unwrap()
            setImportError(undefined)
            setImportedSuccessfully(true)
            setSelectedSector(undefined)
            setSuccessRows(undefined)
            setRowsError(undefined)
            setShowMarkets(false)
        } catch (err) {
            setImportError(`${err}`)
        }
        setImportLoading(false)
    }

    useEffect(() => {
        dispatch(tryToFetchAllCurrencies()).unwrap()
        onGettingAllMarkets()
    }, [])

    const onChangeSelectedSector = (e: any) => {
        setSelectedSector(e)
    }

    const onChangeSelectedCurrency = (e: any) => {
        if (e?.value === 'market') {
            setShowMarkets(true)
        } else {
            setShowMarkets(false)
            setSelectedMarket(undefined)
        }
        setSelectedCurrency(e)
    }

    const onChangeSelectedMarket = (e: any) => {
        setSelectedMarket(e)
    }

    const onClickBasedOnMarket = () => {
        setShowMarkets(!showMarkets)
    }

    const onChangeSelectedTemplate = (e: any) => {
        setSelectedTemplate(e)
    }

    return (
        <Modal
            open={openImportModal}
            showInRight
            contentContainerStyle='!min-w-[700px]'
            onClose={() => !importLoading && onCloseModal()}>
            <div className={'p-2 min-w-[500px]'}>
                {importedSuccessfully ?
                    <SuccessfullyUploadFile
                        handleCloseModal={() => !importLoading && onCloseModal()}
                    />
                    : <UploadCsvFile
                        onUploadFile={onUploadFile}
                        getInputProps={getInputProps}
                        getRootProps={getRootProps}
                        isDragActive={isDragActive}
                        fileSelected={fileSelected}
                        importError={importError}
                        currencies={allCurrencies}
                        selectedCurrency={selectedCurrency}
                        sectors={sectors}
                        templates={templates}
                        showMarket={showMarkets}
                        selectedTemplate={selectedTemplate}
                        selectedMarket={selectedMarket}
                        selectedSector={selectedSector}
                        markets={markets}
                        rowsError={rowsError}
                        showReplacements={showReplacements}
                        onCloseModal={onCloseModal}
                        onAcceptChanges={onAcceptChanges}
                        onClickBasedOnMarket={onClickBasedOnMarket}
                        onChangeSelectedSector={onChangeSelectedSector}
                        onChangeSelectedTemplate={onChangeSelectedTemplate}
                        onChangeSelectedCurrency={onChangeSelectedCurrency}
                        onChangeSelectedMarket={onChangeSelectedMarket}
                    />
                }
            </div>
        </Modal>
    )
}

export default ImportPricingCsvModal;
