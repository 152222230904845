import React, { useState } from 'react';
import Modal from '../../../shared/modal';
import { tryToAddConversationAiAgent, tryToAddNextConversationAiAgent } from '../../../store/brokers/ai-agents/aiAgentsSlice';
import { useAppDispatch } from '../../../app/hooks';
import { useNavigate, useParams } from 'react-router-dom';
import Button from '../../../shared/button';
import { IUploadItems } from '../../../interfaces/agents/IUploadItems';
import moment from 'moment';
import { TAgents } from '../../../interfaces/ai-agents/TAgents';


const AiGradingItemsModal = ({
	uploadedItems,
	type,
	addMoreItems,
	clearSessionId
}: {
	uploadedItems:{ elements: IUploadItems[]; totalElementPrice: number; nrItems: number; }
	type?: TAgents,
	addMoreItems?: () => void,
	sessionId?: string,
	clearSessionId?: () => void
}) => {
	const [showModal, setShowModal] = useState<boolean>(true)
	const { id } = useParams();
	const [isLoading, setIsLoading] = useState<boolean>();
	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	const handleCloseModal = async () => {
		setShowModal(show => !show)
		clearSessionId && clearSessionId()
	};

	const handleSubmitAnswer = async () => {
		if (isLoading) return
		setIsLoading(true)
		const addChat = await dispatch(tryToAddConversationAiAgent({ answer: JSON.stringify(uploadedItems.elements), chatId: id || '', type })).unwrap();
		if (addChat.success && !addChat.finalStep) {
			await dispatch(tryToAddNextConversationAiAgent({ chatId: id || '' })).unwrap();
		}
		handleCloseModal()
	}

	const sum = uploadedItems.elements.reduce((acc, o) => acc + Number(o.price), 0)

	console.log({uploadedItems})

	return (
		<>
			<Modal
				open={showModal}
				onClose={handleCloseModal}
				showInRight
				title={'Batch Asset Breakdown'}
				contentContainerStyle={'w-full'}
			>
				<div className='h-[calc(100vh-175px)]'>
					<hr />
					<div className='w-full flex flex-wrap bg-gradient-to-br from-gradient-start to-gradient-end py-3 px-6 justify-center items-center'>
						<h2 className='text-xl md:text-3xl font-dm_sansbold'>wingpay’s offer</h2>
						<div className='ml-auto flex flex-wrap px-5 py-2 justify-center items-center bg-[#EBEDEF]'>
							<div className='w-6/12'>
								<div className='p-3 min-w-32'>
									<p className='text-base font-normal'>Phones</p>
									<span className='text-[#8A8A8E] text-sm'>{uploadedItems.nrItems || uploadedItems.elements.length} items</span>
								</div>
							</div>
							<div className='w-6/12 text-right'>
								{new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' }).format(uploadedItems.totalElementPrice || sum)}
							</div>
						</div>
					</div>
					<div className='my-4 border border-[#EBEDEF] rounded p-4'>
						<div className='w-full flex flex-wrap items-center'>
							<div className='w-full md:w-6/12'>
								<h5 className='text-sm font-normal'>Batch breakdown</h5>
								<h3 className='font-dm_sansbold text-xl'>AI Grading</h3>
								<span className='text-[#8A8A8E]'>{moment(new Date()).format('DD-MM-YYYY')}</span>
							</div>
							<div className='w-full md:w-6/12 text-right'>
								<p className='text-[#E50000] text-sm font-normal'>Ongoing Sale</p>
								<p className='text-[#8A8A8E] text-sm font-normal'></p>
							</div>
						</div>

						<table className='my-4 w-full'>
							<thead>
								<tr className=''>
									<td className='text-sm px-3 py-1 bg-title text-agentDetails-lightBg rounded-tl-md'>Item Name</td>
									<td className='text-sm px-3 py-1 bg-title text-agentDetails-lightBg'>AI Grading</td>
									<td className='text-sm px-3 py-1 bg-title text-agentDetails-lightBg'>Availability</td>
									<td className='text-sm px-3 py-1 bg-title text-agentDetails-lightBg'>Condition</td>
									<td className='text-sm px-3 py-1 bg-title text-agentDetails-lightBg'>Price</td>
									{<td className='text-sm px-3 py-1 bg-title text-agentDetails-lightBg rounded-tr-md'></td>}
								</tr>
							</thead>
							<tbody>
								{uploadedItems.elements.map((item, index) => (
									<tr key={item.make + index} className='text-xs font-normal'>
										<td className='px-3 py-2'>{item.make} {item.model} {item.storage} {item.network} {item.condition} condition</td>
										<td className='text-primary-default px-3 py-2'>Verified</td>
										<td className='text-[#E50000] px-3 py-2'>Ongoing Sale</td>
										<td className='px-3 py-2'>{item.condition}</td>
										<td className='px-3 py-2'>{new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' }).format(Number(item.price) || 0)}</td>
										<td className='px-3 py-2'>
											{/* {item._id} */}
											{item._id && <div onClick={() => item?._id && navigate(item?._id)}>
												<img title={'See Asset Library Details'} alt='item details' src={'/assets/table-actions/visibility.svg'} className={`w-[20px] min-w-[20px] mr-3 mt-1 object-contain cursor-pointer`} />
											</div>}
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>

				</div>
				<div className='mt-auto flex justify-end'>
					{addMoreItems && <Button
						onClickButton={addMoreItems}
						className='border border-title py-3 px-6 rounded-lg mx-3'
						label='Add more items' />}
					<Button
						loading={isLoading}
						onClickButton={addMoreItems ? handleSubmitAnswer : handleCloseModal}
						className='bg-gradient-to-br from-gradient-start to-gradient-end py-3 px-6 rounded-lg'
						label={'Close the modal'} />
				</div>
			</Modal>
		</>
	)
}

export default AiGradingItemsModal;