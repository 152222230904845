import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { tryToFetchAssetTemplateFilters } from '../../../store/brokers/admin/asset-templates/assetsTemplatesSlice';
import { tryToFetchFilteredPricings } from '../../../store/brokers/partners/pricing/pricingSlice';
import { tryToFindOperatingMarkets } from '../../../store/brokers/admin/partners/partnersSlice';
import {
    tryToAddPriceSettings,
    tryToEditPriceSetting,
    tryToFetchSinglePriceSetting
} from '../../../store/brokers/shared-endpoints/pricing-reviews/pricingReviewsSlice';
import { useUserProfileMutation } from '../../../store/user/userDomApi';
import { IEditPricingReview } from '../../../interfaces/pricing-review/IEditPricingReview';
import { IPricingReview } from '../../../interfaces/pricing-review/IPricingReview';
import { IPaginationPayload } from '../../../interfaces/shared/IPaginationPayload';
import { IActiveMarket } from '../../../interfaces/markets/IActiveMarket';
import { IToast } from '../../../interfaces/components/IToast';
import DownloadSampleModal from './modals/DownloadSampleModal';
import PricingActionsModal from './modals/PricingActionsModal';
import DownloadPricingModal from './modals/DownloadPricingModal';
import ImportPricingCsvModal from './modals/ImportPricingCsvModal';
import PricingClassification from './details/PricingClassification';
import CurrencyConverterModal from './modals/CurrencyConverterModal';
import ChangePricesModal from './modals/ChangePricesModal';
import ConvertOnceModal from './modals/ConvertOnceModal';
import PricingFilters from './details/PricingFilters';
import PricingTable from './tables/PrincingTable';
import SearchWithButton from '../../../shared/search-with-button';
import Button from '../../../shared/button';
import Toast from '../../../shared/toast';


const Pricing = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [rows, setRows] = useState<Array<any>>([]);
    const [search, setSearch] = useState<string>();
    const [sort, setSort] = useState<string>();
    const [templateFilters, setTemplateFilters] = useState<Array<any>>();
    const [categorySelected, setCategorySelected] = useState<string>();
    const [showToast, setShowToast] = useState<IToast | null>();
    const [userSectors, setUserSectors] = useState<Array<any>>();
    const [pricingRowId, setPricingRowId] = useState<string>();
    const [filterValues, setFilterValues] = useState<any>();
    const [priceSettingData, setPriceSettingData] = useState<IPricingReview>();
    const [loadingUpdate, setLoadingUpdate] = useState<boolean>(false);
    const [showActionModal, setShowActionModal] = useState<boolean>(false);
    const [showImportCsvModal, setShowImportCsvModal] = useState<boolean>(false);
    const [loadingPriceSetting, setLoadingPriceSetting] = useState<boolean>(false);
    const [showDownloadSampleModal, setShowDownloadSampleModal] = useState<boolean>(false);
    const [showDownloadPricingModal, setShowDownloadPricingModal] = useState<boolean>(false);
    const [showBatchCurrencyModal, setShowBatchCurrencyModal] = useState<boolean>(false)
    const [paginationState, setPaginationState] = useState<IPaginationPayload>({
        pageNumber: 1,
        pageSize: 10,
    });
    const [showChangePrices, setShowChangePrices] = useState<boolean>(false)
    const [showCurrencyConverter, setShowCurrencyConverter] = useState<boolean>(false)
    const [activeMarket, setActiveMarket] = useState<IActiveMarket>()
    const [pageAccess, setPageAccess] = useState<any>()
    const activeMarketState = useAppSelector((state) => state?.partners?.partnerActiveMarket);
    const paginationData = useAppSelector((state) => state?.pricings?.pricings?.data?.page);
    const accessControl = useAppSelector((state) => state?.partners?.partnerNavigation);
    const priceLoading = useAppSelector((state) => state.pricings.pricingsAreLoading);
    const stateFilters = useAppSelector((state) => state.assetsTemplates);
    const state = useAppSelector((state) => state.pricings);
    const [userProfileMutation] = useUserProfileMutation();

    useEffect(() => {
        const findPage = accessControl && accessControl?.length > 0 && accessControl?.find((item: any) => item?.name === 'Pricing')
        const findChild = findPage && findPage?.children && findPage?.children?.length > 0 && findPage?.children?.find((item: any) => item?.path === '')
        setPageAccess(findChild || undefined)
    }, [accessControl])

    useEffect(() => {
        if (stateFilters.assetTemplateFilters) {
            setTemplateFilters(stateFilters.assetTemplateFilters)
        }
    }, [stateFilters?.assetTemplateFilters]);

    const onResetPaginationSettings = () => {
        setPaginationState({
            pageNumber: 1,
            pageSize: paginationState?.pageSize || 10
        })
    }

    useEffect(() => {
        if (state.pricings) {
            const rowsData =
                state.pricings?.data?.elements && Array.isArray(state.pricings?.data.elements)
                    ? state.pricings?.data.elements.map((r) => ({ ...r }))
                    : [];
            if (rowsData !== rows) {
                setRows(rowsData);
            }
        }
    }, [state.pricings]);

    const onGetUserSectorsAndMarkets = async () => {
        const response = await userProfileMutation(null).unwrap()
        const formatSectors: any = response?.data?.sectors?.length > 0 ? response?.data?.sectors?.map((s: any) => ({ label: s?.displayName || s?.name, value: s?.id, enabledIcon: s?.enabledIcon, disabledIcon: s?.disabledIcon })) : []
        setCategorySelected(formatSectors?.[0]?.value)
        onResetPaginationSettings()
        setUserSectors(formatSectors || [])
    }

    const onGettingPricingData = async () => {
        try {
            if (categorySelected && activeMarket) {
                const filters = filterValues && Object.keys(filterValues)
                let formatFilters
                const filtersValuesFormat = filters && filters?.length > 0 && filters?.map((item: any) => {
                    return {
                        key: item,
                        value: Object.keys(filterValues) ? filterValues?.[item] || undefined : undefined
                    }
                })
                if (filtersValuesFormat && filtersValuesFormat?.length > 0) {
                    formatFilters = {
                        value: filtersValuesFormat?.filter((key: any) => key?.value)
                    }
                }
                let data: any = {
                    filters: {
                        assetTemplateId: categorySelected,
                    }
                }
                if (activeMarket) {
                    data = {
                        ...data || {},
                        filters: {
                            ...data?.filters || {},
                            country: activeMarket?.market?.name
                        }
                    }
                }
                if (search) {
                    data = {
                        ...data,
                        filters: {
                            ...data?.filters || {},
                            search: search
                        }
                    }
                }
                if (sort) {
                    data = {
                        ...data,
                        sort,
                    }
                }
                if (formatFilters && formatFilters?.value) {
                    data = {
                        ...data,
                        filters: {
                            ...data?.filters || {},
                            ...formatFilters || {}
                        }
                    }
                }
                const fetchData = {
                    ...paginationState || {},
                    data
                }
                await dispatch(tryToFetchFilteredPricings(fetchData)).unwrap()
            }
        } catch (err) {
            // error here
            setRows([])
        }
    }

    useEffect(() => {
        onGetUserSectorsAndMarkets()
        dispatch(tryToFindOperatingMarkets(null))
    }, [])

    useEffect(() => {
        if (activeMarket) {
            onGettingPricingData()
        }
    }, [paginationState, activeMarket, sort])

    useEffect(() => {
        if (activeMarketState && ((JSON.stringify(activeMarketState) !== JSON.stringify(activeMarket)))) {
            setActiveMarket(activeMarketState || undefined)
            onResetPaginationSettings()
        }
    }, [activeMarketState])

    useEffect(() => {
        if (categorySelected) {
            dispatch(tryToFetchAssetTemplateFilters({ id: categorySelected }))
        }
        setFilterValues(undefined)
    }, [categorySelected])

    const onClickSortPricing = (name?: string) => {
        setSort((!sort || sort === 'desc') ? 'asc' : 'desc')
    }

    const onChangeSearchValue = (value: string | null | undefined, type: string) => {
        setSearch(value || undefined)
        onResetPaginationSettings()
    }

    const onAddOrEditPriceSetting = async (priceSettings?: IEditPricingReview) => {
        if (loadingUpdate) {
            return;
        }
        setLoadingUpdate(true)
        if (pricingRowId) {
            try {
                if (priceSettingData) {
                    const data: IEditPricingReview = {
                        numberOfItems: priceSettings?.numberOfItems || priceSettingData?.numberOfItems,
                        amount: priceSettings?.amount ? Number(priceSettings?.amount) : priceSettingData?.amount,
                        _id: priceSettingData?._id
                    }
                    await dispatch(tryToEditPriceSetting(data)).unwrap()
                    setShowToast({ type: 'success', message: 'Price Offer successfully updated' });
                    setShowActionModal(false)
                } else {
                    if (priceSettings?.amount && priceSettings?.numberOfItems) {
                        const data: IPricingReview = {
                            ...priceSettings,
                            marketId: activeMarket?.market?._id || '',
                            offerId: pricingRowId
                        }
                        await dispatch(tryToAddPriceSettings(data)).unwrap()
                        setShowToast({ type: 'success', message: 'Price Offer successfully saved' });
                        setShowActionModal(false)
                    }
                }
            } catch (err: any) {
                setShowToast({ type: 'error', message: `${err?.response?.data || err?.response || err}` });
            }
            setLoadingUpdate(false)
        }
    }

    const getPriceSettingForPricingRow = async (offerId: string) => {
        try {
            const response = await dispatch(tryToFetchSinglePriceSetting(offerId)).unwrap()
            setPriceSettingData(response)
        } catch (err) {
            setPriceSettingData(undefined)
            setLoadingPriceSetting(false)
        }
    }

    useEffect(() => {
        setLoadingPriceSetting(false)
    }, [priceSettingData])

    const onOpenActionModal = (offerId: string) => {
        setLoadingPriceSetting(true)
        getPriceSettingForPricingRow(offerId)
        setShowActionModal(true)
        setPricingRowId(offerId)
    }

    const onCloseActionsModal = () => {
        setShowActionModal(false)
        setPricingRowId(undefined)
    }

    const onToggleDownloadSampleModal = () => {
        if (!showDownloadSampleModal) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }
        setShowDownloadSampleModal(!showDownloadSampleModal)
    }

    const onToggleDownloadPricingModal = () => {
        if (!showDownloadPricingModal) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }
        setShowDownloadPricingModal(!showDownloadPricingModal)
    }

    const onToggleImportCsvModal = () => {
        setShowImportCsvModal(!showImportCsvModal)
    }

    const onClickCategory = (categoryId: string) => {
        setCategorySelected(categoryId)
        onResetPaginationSettings()
    }

    const onChangeSelectedFilterValue = (value: any, type?: string) => {
        if (type) {
            const findField = templateFilters?.find((field) => field?.name === type);
            setFilterValues({
                ...(filterValues || {}),
                [type]: findField?.dataType === 'boolean' ? value?.value === 'true' : value?.value || undefined,
            });
            onResetPaginationSettings()
        }
    };

    const onChangeFilterInput = (value: string | null | undefined, type: string) => {
        const findField = templateFilters?.find((field) => field?.name === type);
        setFilterValues({
            ...(filterValues || {}),
            [type]:
                findField?.dataType === 'number' ? Number(value || 0) : value,
        });
        onResetPaginationSettings()
    };

    const onImportSuccessfully = () => {
        // onGettingPricingData()
    }

    const onClearAllFilters = () => {
        setFilterValues(undefined)
        onResetPaginationSettings()
    }

    const onToggleChangePrice = () => {
        setShowChangePrices(!showChangePrices)
    }

    const onToggleCurrencyConverter = () => {
        setShowCurrencyConverter(!showCurrencyConverter)
    }

    const onToggleBatchCurrencyConverter = () => {
        setShowBatchCurrencyModal(!showBatchCurrencyModal)
    }

    const onClickSeePriceEffects = () => {
        navigate('/pricing/price-effects')
    }

    return (
        <div>
            <div className='mb-5'>
                <p className='page-title'>Pricing</p>
            </div>
            <div className='my-10'>
                <PricingClassification
                    items={userSectors || []}
                    activeButton={categorySelected}
                    onClickCategory={onClickCategory}
                />
                {categorySelected && templateFilters && templateFilters?.length > 0 &&
                    <PricingFilters
                        items={templateFilters || []}
                        filterValues={filterValues || null}
                        onChangeInput={onChangeFilterInput}
                        onChangeSelectedValue={onChangeSelectedFilterValue}
                        onClearAllFilters={onClearAllFilters}
                    />
                }
                <div className='flex sm:flex-col md:flex-row  sm:justify-center md:justify-end lg:justify-end flex-wrap'>
                    <Button
                        label='Clear all filters'
                        dataQa={'clear-all-filters'}
                        className='btn-primary-text-underline min-w-auto'
                        onClickButton={onClearAllFilters}
                    />
                    <Button
                        label={'Change Pricing'}
                        dataQa={'change-pricing'}
                        className={`${(!accessControl || pageAccess?.actions?.['Change Pricing']) ? 'btn-main' : 'btn-main-disable'} sm:mb-1 lg:mb-0 min-w-[150px]`}
                        onClickButton={() => (!accessControl || pageAccess?.actions?.['Change Pricing']) && onToggleChangePrice()}
                    />
                    <Button
                        label={'Convert Once'}
                        dataQa={'convert-once'}
                        className={`${(!accessControl || pageAccess?.actions?.['Convert Once']) ? 'btn-main' : 'btn-main-disable'} sm:mb-1 lg:mb-0 sm:ml-0 md:ml-2 min-w-[150px]`}
                        onClickButton={() => (!accessControl || pageAccess?.actions?.['Convert Once']) && onToggleBatchCurrencyConverter()}
                    />
                    <Button
                        label={'Currency Converter'}
                        dataQa={'currency-converter'}
                        className={`${(!accessControl || pageAccess?.actions?.['Currency Converter']) ? 'btn-main' : 'btn-main-disable'} sm:mb-1 lg:mb-0 sm:ml-0 md:ml-2 min-w-[150px]`}
                        onClickButton={() => (!accessControl || pageAccess?.actions?.['Currency Converter']) && onToggleCurrencyConverter()}
                    />
                </div>
            </div>
            <div className='mb-4 mt-2'>
                <div className='bg-white pl-4 pt-2'>
                    <div className='flex flex-row justify-between items-center'>
                        <div>
                            <p className='text-xl font-bold mb-2 pl-3'>Pricing List</p>
                            <p className='text-sm font-bold mb-10 pl-3 text-primary-light'>Current state of asset prices</p>
                        </div>
                        <Button
                            label='Price Effects'
                            dataQa={'price-effects'}
                            className={`${(!accessControl || pageAccess?.actions?.['Price Effects']) ? 'btn-main' : 'btn-main-disable'} min-w-[200px] mb-6`}
                            onClickButton={() => (!accessControl || pageAccess?.actions?.['Price Effects']) && onClickSeePriceEffects()}
                        />
                    </div>
                    <SearchWithButton
                        buttonLabel='Import Pricing'
                        placeholder='Filter table'
                        onChangeSearchValue={onChangeSearchValue}
                        search={search}
                        showScndBtn={true}
                        showThirdBtn={true}
                        scndButtonLabel={'Download Catalog'}
                        thirdButtonLabel={'Download Pricing'}
                        onClickThirdButton={() => (!accessControl || pageAccess?.actions?.['Download Pricing']) && onToggleDownloadPricingModal()}
                        onClickSecondButton={() => (!accessControl || pageAccess?.actions?.['Download Catalog']) && onToggleDownloadSampleModal()}
                        onClickButton={() => (!accessControl || pageAccess?.actions?.['Import Pricing']) && onToggleImportCsvModal()}
                        scndButtonStyle={`${(!accessControl || pageAccess?.actions?.['Download Catalog']) ? 'btn-main' : 'btn-main-disable'} sm:col-span-4  md:col-span-6  lg:col-span-2 pb-2 sm:mb-1 lg:mb-0 sm:mr-0 lg:mr-2 !min-w-[90%]`}
                        thirdButtonStyle={`${(!accessControl || pageAccess?.actions?.['Download Pricing']) ? 'btn-main' : 'btn-main-disable'} sm:col-span-4  md:col-span-6  lg:col-span-2 pb-2 sm:mb-1 lg:mb-0 sm:mr-0 lg:mr-2 !min-w-[90%]`}
                        buttonStyle={`${(!accessControl || pageAccess?.actions?.['Import Pricing']) ? 'btn-main' : 'btn-main-disable'} sm:col-span-4 md:col-span-6 lg:col-span-2 sm:mb-1 lg:mb-0 pb-2 !min-w-[90%]`}
                        mainContainerStyle={'sm:grid-cols-4 md:grid-cols-6 lg:grid-cols-10'}
                    />
                </div>
                <div>
                    <PricingTable
                        rows={rows}
                        pricingLoading={priceLoading}
                        paginationData={paginationData}
                        accessPricingAction={!accessControl || pageAccess?.actions?.['Pricing Actions']}
                        setPaginationState={setPaginationState}
                        state={state}
                        showHighestPrice={sort === 'desc'}
                        onClickSortPricing={onClickSortPricing}
                        activeMarket={activeMarket}
                        onOpenActionModal={onOpenActionModal}
                    />
                </div>
                {showActionModal &&
                    <PricingActionsModal
                        openActionsModal={showActionModal}
                        existingData={priceSettingData}
                        loadingUpdate={loadingUpdate}
                        loadingPriceSettings={loadingPriceSetting}
                        handleCloseActionsModal={onCloseActionsModal}
                        onAddOrEditPriceSetting={onAddOrEditPriceSetting}
                    />}
                {showDownloadSampleModal &&
                    <DownloadSampleModal
                        openDownloadModal={showDownloadSampleModal}
                        handleCloseDownloadModal={onToggleDownloadSampleModal}
                        sectors={userSectors || []}
                    />
                }
                {showDownloadPricingModal &&
                    <DownloadPricingModal
                        openDownloadModal={showDownloadPricingModal}
                        handleCloseDownloadModal={onToggleDownloadPricingModal}
                        sectors={userSectors || []}
                        activeMarket={activeMarket}
                    />
                }
                {showImportCsvModal &&
                    <ImportPricingCsvModal
                        openImportModal={showImportCsvModal}
                        handleCloseModal={onToggleImportCsvModal}
                        onImportSuccessfully={onImportSuccessfully}
                        sectors={userSectors || []}
                    />
                }
                {showChangePrices &&
                    <ChangePricesModal
                        openModal={showChangePrices}
                        handleCloseModal={onToggleChangePrice}
                    />
                }
                {showCurrencyConverter &&
                    <CurrencyConverterModal
                        categories={userSectors}
                        openModal={showCurrencyConverter}
                        handleCloseModal={onToggleCurrencyConverter}
                    />
                }
                {showBatchCurrencyModal &&
                    <ConvertOnceModal
                        categories={userSectors || []}
                        openModal={showBatchCurrencyModal}
                        handleCloseModal={onToggleBatchCurrencyConverter}
                    />
                }
                {showToast?.message &&
                    <Toast
                        type={showToast?.type}
                        text={showToast?.message}
                        onHandleCloseToast={() => setShowToast(null)}
                    />
                }
            </div>
        </div>
    )
}

export default Pricing;