import React from 'react';
import { IActiveMarket } from '../../../../interfaces/markets/IActiveMarket';
import Error from '../../../../shared/error';
import Button from '../../../../shared/button';
import SelectCheckbox from '../../../../shared/select-checkbox';


interface IUploadCsvFile {
    getRootProps: any;
    getInputProps: any;
    isDragActive: boolean;
    fileSelected?: any;
    templates?: any;
    importError?: string;
    sectors: Array<any>;
    importLoading?: boolean;
    selectedSector?: any;
    selectedCurrency?: any;
    currencies: Array<any>;
    activeMarket?: IActiveMarket;
    showMarket?: boolean;
    markets: Array<any>;
    rowsError?: any;
    selectedMarket?: any;
    selectedTemplate?: any;
    showReplacements?: any;
    onAcceptChanges: () => void;
    onChangeSelectedSector: (e: any) => void;
    onChangeSelectedCurrency: (e: any) => void;
    onChangeSelectedMarket: (e: any) => void;
    onChangeSelectedTemplate: (e: any) => void;
    onUploadFile: () => void;
    onClickBasedOnMarket: () => void;
    onCloseModal: () => void;
}

const UploadCsvFile = ({
    getRootProps,
    getInputProps,
    isDragActive,
    fileSelected,
    importError,
    templates,
    currencies,
    sectors,
    selectedSector,
    selectedCurrency,
    selectedTemplate,
    showMarket,
    markets,
    selectedMarket,
    importLoading,
    rowsError,
    showReplacements,
    onAcceptChanges,
    onChangeSelectedSector,
    onChangeSelectedCurrency,
    onChangeSelectedMarket,
    onChangeSelectedTemplate,
    onUploadFile,
    onClickBasedOnMarket,
    onCloseModal
}: IUploadCsvFile) => {
    return (
        <div className='p-3'>
            <div className='mb-3'>
                <div className='border-b-2 border-[#e4e4e4] mb-4'>
                    <p className='text-sm font-semibold'>Import Category Pricing (CSV or Excel)</p>
                </div>
                <p className='text-md mb-2 mt-1'>Select Category and Currency/Market to import Pricing.</p>
                {importError && <Error text={importError} />}
                {(rowsError && rowsError?.length > 0) &&
                    <div className='flex flex-col !text-red-600'>
                        <p className='mb-2'>These rows were not valid and can&lsquo;t be uploaded: </p>
                        <div className="flex flex-wrap">
                            {rowsError?.map((item: any, index: number) => (
                                <span key={index} className="mb-1">
                                    {index === 0 ? '' : ', '}
                                    {item?.index}
                                </span>
                            ))}
                        </div>
                    </div>
                }
            </div>
            <div className='flex flex-col items-center bg-[#f8f9fc] rounded-[6px] p-3'>
                <SelectCheckbox
                    name='Category'
                    dataQa={'category'}
                    placeholder='Select'
                    containerStyle='mb-1 min-w-full'
                    selectedOption={selectedSector}
                    onChangeSelectedOption={onChangeSelectedSector}
                    options={sectors || []}
                />
                <SelectCheckbox
                    name='Currency'
                    dataQa={'currency'}
                    placeholder='Select'
                    containerStyle='mb-1 min-w-full'
                    selectedOption={selectedCurrency}
                    disabled={showMarket}
                    onChangeSelectedOption={onChangeSelectedCurrency}
                    options={currencies || []}
                />
                <div className='flex flex-row justify-start items-center mb-6 min-w-[100%]'>
                    <div className='flex items-center mr-2'>
                        <input
                            onChange={onClickBasedOnMarket}
                            checked={showMarket}
                            id='checkbox-all'
                            type='checkbox'
                            data-qa={'checkbox'}
                            className='w-4 h-4 !outline-none text-blue-600 bg-gray-100 border-gray-300 accent-green-600 rounded focus:accent-green-600 focus:ring-2'
                        />
                        <label htmlFor='checkbox-all' className='sr-only'>
                            checkbox
                        </label>
                    </div>
                    <p>
                        Import based on Market.
                    </p>
                </div>
                {showMarket &&
                    <SelectCheckbox
                        name='Market'
                        dataQa={'market'}
                        placeholder='Select'
                        containerStyle='mb-1 min-w-full'
                        selectedOption={selectedMarket}
                        onChangeSelectedOption={onChangeSelectedMarket}
                        options={markets || []}
                    />
                }
                <SelectCheckbox
                    name='Template'
                    dataQa={'template'}
                    placeholder='Select'
                    containerStyle='mb-1 min-w-full'
                    selectedOption={selectedTemplate}
                    onChangeSelectedOption={onChangeSelectedTemplate}
                    options={templates || []}
                />
            </div>
            <div className='flex flex-col items-center justify-center min-h-[200px] bg-[#f8f9fc] rounded-[6px] p-3 my-2'>
                <div className='flex flex-col items-center justify-center' {...getRootProps()}>
                    <input {...getInputProps()} />
                    {
                        isDragActive ?
                            <p>Drop the files here ...</p> :
                            <p className='font-semibold'>Drag and drop CSV or Excel file here</p>
                    }
                    <p className='mb-2 font-semibold'>
                        or
                    </p>
                    <Button
                        icon={<img src='/assets/shared/upload-file.svg' className='w-[30px] object-contains pr-2' />}
                        className={'btn-main !bg-[#202020] !text-white !py-2 !shadow-none flex flex-row items-center'}
                        label={'Select File'}
                        dataQa={'select-file'}
                    />
                    {fileSelected && <p>{fileSelected?.name}</p>}
                </div>
            </div>
            {showReplacements?.show &&
                <div className='my-5 rounded-[6px] p-3 bg-[#fdecd2]'>
                    <p className='font-semibold text-[16px] mb-4'>Suggested Replacements for File Columns</p>
                    <div className='grid grid-cols-7 my-2 mx-1'>
                        <p className='col-span-3'>Source Table</p>
                        <p className='col-span-1'></p>
                        <p className='col-span-3'>Destination Table</p>
                    </div>
                    {showReplacements?.data && showReplacements?.data?.length > 0 && showReplacements?.data?.map((item: any, index: number) => {
                        return (
                            <div key={index} className='grid grid-cols-7 item-center my-2 mx-1'>
                                <p className='col-span-3 mt-2'>{item?.name}</p>
                                <img src={'/assets/shared/arrow-black-right.svg'} className='object-content h-[17px] mt-2' />
                                <input
                                    value={item?.replaceWith}
                                    className='col-span-3 border border-borderColor rounded w-full px-3 py-2 cursor-default'
                                />
                            </div>
                        )
                    })}
                    <p className='italic mt-4'>You can choose to accept or decline these suggestions as needed.</p>
                </div>
            }
            {showReplacements?.show ?
                <div className='flex flex-row justify-center items-center my-2'>
                    <Button
                        label={'Ignore'}
                        className={'btn-main !font-normal !shadow-none min-w-[120px] !rounded mr-3'}
                        onClickButton={onCloseModal}
                    />
                    <Button
                        label={'Accept Suggestions'}
                        onClickButton={() => !importLoading && !rowsError && onAcceptChanges()}
                        className={'btn-primary-rounded-less !font-normal !shadow-none min-w-[150px]'}
                    />
                </div>
                : <div className='flex flex-col items-center my-2'>
                    <Button
                        label='Upload File'
                        dataQa={'upload-file'}
                        onClickButton={() => !importLoading && fileSelected && !rowsError && onUploadFile()}
                        className={`${fileSelected && !importLoading ? 'btn-main' : 'btn-main-disable'} !py-2 !shadow-none min-w-[150px]`}
                    />
                </div>
            }
        </div>
    )
}

export default UploadCsvFile;