import React, { useEffect, useLayoutEffect, useState } from 'react';
import Input from '../../../shared/input';
import { useLocation, useSearchParams } from 'react-router-dom';
import { IPaginationPayload } from '../../../interfaces/shared/IPaginationPayload';
import AssetLibraryFilters from './filters/AssetLibraryFilters';
import { ISelectedValue } from '../../../interfaces/components/ISelectedValue';
import AssetLibraryTable from './tables/AssetLibraryTable';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { tryToFetchUserData } from '../../../store/brokers/admin/partners/partnersSlice';
import { IActiveMarket } from '../../../interfaces/markets/IActiveMarket';
import { tryToFetchAssets } from '../../../store/brokers/partners/asset-library/assetLibrarySlice';
import BatchAssetBreakdown from './details/BatchAssetBreakdown';

const useQuery = () => {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

const AssetLibrary = () => {
	const dispatch = useAppDispatch();
	const location = useLocation();
	const query = useQuery();
	const [detailsBatchDetailId, setDetailsBatchDetailsId] = useState<string>();
	const [rows, setRows] = useState<Array<any>>([]);
	const [brokerCategories, setBrokerCategories] = useState<any>();
	const [pageAccess, setPageAccess] = useState<any>();
	const accessControl = useAppSelector((state) => state?.partners?.partnerNavigation);
	const salesLoading = useAppSelector((state) => state.assetLibrary.assetsAreLoading);
	const paginationData = useAppSelector((state) => state?.assetLibrary?.assets?.data?.page);
	const [search, setSearch] = useState<string>();
	const [selectedCategory, setSelectedCategory] = useState<any>();
	const [selectedStatus, setSelectedStatus] = useState<any>();
	const [searchParams, setSearchParams] = useSearchParams();
	const [paginationState, setPaginationState] = useState<IPaginationPayload>({
        pageNumber: 1,
        pageSize: 10,
    });
	const [activeMarket, setActiveMarket] = useState<IActiveMarket>();
	const {partnerActiveMarket: activeMarketState, userData: user} = useAppSelector((state) => state?.partners);
	const state = useAppSelector((state) => state.assetLibrary);

	const getBrokerSectors = async () => {
        const response = user;
        const formatSectors: any = (response?.sectors?.length > 0) ? response?.sectors?.map((s: any) => ({ ...s, label: s?.displayName || s?.name, value: s?.name })) : [];
        setBrokerCategories(formatSectors || []);
    };

	const onGettingFilters = () => {
        let data: {
			marketId?: string,
			assetTemplateId?: string,
			status?: string,
			search?: string,
		} = {}
        if (activeMarket) {
            data = {
                ...data || {},
                marketId: activeMarket?.market?._id,
            }
        }
        if (selectedCategory) {
            data = {
                ...data || {},
                assetTemplateId: selectedCategory?.id,
            }
        }
        if (selectedStatus) {
            data = {
                ...data || {},
                status: selectedStatus?.value,
            }
        }
        if (search) {
            data = {
                ...data || {},
                search: search,
            }
        }
        const formatData: any = { data }
        return formatData;
    }

	const onGettingSalesData = async () => {
		try {
			const data = onGettingFilters()
			const fetchData = {
				...paginationState || {},
				sessionId: user.sessionId,
				...data || {}
			}
			await dispatch(tryToFetchAssets(fetchData)).unwrap()
		} catch (err) {
			console.log({err})
			// err
		}
    }

	useEffect(() => {
		if (user) {
			onGettingSalesData()
		}
    }, [paginationState, location, user])

    useEffect(() => {
        if (user) {
            getBrokerSectors();
        }
    }, [user]);

	useEffect(() => {
        if (state.assets) {
            const rows = state.assets?.items?.elements;
			console.log({rows}, state.assets)
            setRows(rows);
        }
    }, [state.assets]);

	useEffect(() => {
        dispatch(tryToFetchUserData())
    }, [location])

	useEffect(() => {
        const findPage = accessControl && accessControl?.length > 0 && accessControl?.find((item: any) => item?.name === 'Asset Library')
        const findChild = findPage && findPage?.children && findPage?.children?.length > 0 && findPage?.children?.find((item: any) => item?.path === '')
        setPageAccess(findChild || undefined)
    }, [accessControl])
	
	const onResetPaginationState = () => {
        setPaginationState({
            pageNumber: 1,
            pageSize: paginationState?.pageSize || 10
        })
    }

	const onChangeFilterSelected = (value: ISelectedValue, type?: string) => {
        if (type) {
            switch (type) {
                case 'status':
                    setSelectedStatus(value)
                    break;
                case 'category':
                    setSelectedCategory(value)
                    break;
            }
            onResetPaginationState()
        }
    }

	useEffect(() => {
        if (activeMarketState && ((JSON.stringify(activeMarketState) !== JSON.stringify(activeMarket)))) {
            setActiveMarket(activeMarketState || undefined)
            onResetPaginationState()
        }
    }, [activeMarketState])

	useLayoutEffect(() => {
        if (query?.get('search')) {
            setSearch(query?.get('search') || undefined)
            onResetPaginationState()
        }
    }, [query?.get('search')])

	const onChangeSearchValue = (value: string | null | undefined, type: string) => {
        setSearch(value || undefined)
        onResetPaginationState()
        if (value) searchParams.set('search', value)
        else searchParams.delete('search')
        setSearchParams(searchParams)
    }

	const handleSeeDetails = (id: string) => setDetailsBatchDetailsId(id)

	const handleClearId = () => setDetailsBatchDetailsId('')

	return (
		<div>
			{detailsBatchDetailId && <BatchAssetBreakdown id={detailsBatchDetailId} clearId={handleClearId} />}
            <div>
                <h1 className='page-title'>Asset Library </h1> 
            </div>
            <AssetLibraryFilters
                brokerCategories={brokerCategories}
                selectedCategory={selectedCategory}
                selectedStatus={selectedStatus}
                onClickFilter={onChangeFilterSelected}
            />
			<div className={`flex flex-row justify-between items-center bg-[#f8f0e6] mt-3 mb-2 p-1 !rounded`}>
                <div>
                    <Input
                        placeholder={'Filter Table'}
                        dataQa={'search'}
                        containerStyle='mb-0 pb-0 relative min-w-[400px]'
                        inputStyle={`mb-0 border-0 focus:outline-none`}
                        icon={'/assets/shared/search.svg'}
                        showValue={true}
                        inputValue={search}
                        inputUniqueName={'search'}
                        onChangeInput={onChangeSearchValue}
                        hideInputSpace={true}
                    />
                </div>
			</div>
			<div>
				<AssetLibraryTable 
					rows={rows}
                    rowsLoading={salesLoading}
                    paginationData={paginationData}
                    accessControl={accessControl}
                    actions={pageAccess?.actions}
                    setPaginationState={setPaginationState}
                    onSeeDetails={handleSeeDetails}
				/>
			</div>
		</div>

	)
}

export default AssetLibrary;