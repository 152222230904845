import React from 'react';
import { IPageActions } from '../../../../interfaces/components/IPageAccess';
import { IAccessControl } from '../../../../interfaces/components/IAccessControl';
import { IPage } from '../../../../interfaces/models/models/paginated-response.model';
import { IPaginationPayload } from '../../../../interfaces/shared/IPaginationPayload';
import Table from '../../../../shared/table';

const tableColumns = ['Asset Template', 'Partner', 'Pay Service', 'Commission Fee', 'Min Value', 'Max Value', 'Actions']

interface ICommissionFeesTable {
    rows: any[];
    paginationData?: IPage;
    accessControl?: IAccessControl;
    actions?: IPageActions;
    rowsLoading: boolean;
    setPaginationState: ({ pageNumber, pageSize }: IPaginationPayload) => void;
    onDeleteCommissionFee: (id: string) => void;
    onEditCommissionFee: (id: string) => void;
}

const CommissionFeesTable = ({
    rows,
    paginationData,
    accessControl,
    actions,
    rowsLoading,
    setPaginationState,
    onEditCommissionFee,
    onDeleteCommissionFee,
}: ICommissionFeesTable) => {
    return (
        <Table
            columns={tableColumns}
            paginationData={paginationData}
            setPaginationState={setPaginationState}
        >
            {rowsLoading ?
                <>
                    {[...Array(4)].map((x, i) => {
                        return (
                            <tr key={i}>
                                {[...Array(tableColumns?.length || 1)].map((x, j) => {
                                    return (
                                        <td key={j} className='td-1 !min-w-[100%] text-center py-5'><span></span></td>
                                    )
                                })}
                            </tr>
                        )
                    })}
                </>
                : <>
                    {rows?.length > 0 && rows?.map((row: any, idx: number) => {
                        return (
                            <tr key={idx} className='bg-white border-b hover:bg-gray-50'>
                                <td className='px-6 py-4'>{row?.assetTemplate?.name || ''}</td>
                                <td className='px-6 py-4'>{row?.partner?.username || ''}</td>
                                <td className='px-6 py-4'>
                                    {row?.payServices && row?.payServices?.map((item: any, index: number) => `${index ? ', ' : ''}${item?.displayName}`)}
                                    {/* {row?.payServices?.displayName || ''} */}
                                </td>
                                <td className='px-6 py-4'>{row?.commissionFee || 0}</td>
                                <td className='px-6 py-4'>{row?.minValue || ''}</td>
                                <td className='px-6 py-4'>{row?.maxValue || ''}</td>
                                <td className='px-6 py-4'>
                                    <div className='flex flex-row items-center'>
                                        <div data-qa={'edit-commission-fee'} onClick={() => ((!accessControl || actions?.['Edit Commission Fee']) && row?._id) && onEditCommissionFee(row?._id)}>
                                            <img title='Edit Commission Fee' alt='edit item' src={'/assets/table-actions/pen.svg'} className={`h-[14px] object-contain ${(!accessControl || actions?.['Edit Commission Fee']) ? 'cursor-pointer' : 'cursor-not-allowed'} mr-3`} />
                                        </div>
                                        <div data-qa={'delete-commission-fee'} onClick={() => ((!accessControl || actions?.['Delete Commission Fee']) && row?._id) && onDeleteCommissionFee(row?._id)}>
                                            <img title={'Delete Commission Fee'} alt='delete commission fee' src={'/assets/table-actions/delete-close.svg'} className={`h-[14px] object-contain ${(!accessControl || actions?.['Delete Commission Fee']) ? 'cursor-pointer' : 'cursor-not-allowed'} mr-1`} />
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        )
                    })}
                </>
            }
        </Table>
    )
}

export default CommissionFeesTable;