import React, { useEffect, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { IItemAiAgent } from '../../interfaces/ai-agents/IItem';
import FileItem from './item-render/FileItem';
import { tryToAddNextConversationAiAgent, tryToFetchConversations } from '../../store/brokers/ai-agents/aiAgentsSlice';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import InventoryDetailsItem from './item-render/InventoryDetailsItem';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import LogisticDetailsItem from './item-render/LogisticDetailsItem';
import MatchesDetailsItem from './item-render/MatchesDetailsItem';
import GetConfirmationDetailsItem from './item-render/GetConfirmationDetailsItem';
import LinkHighlighter from '../../shared/link-highlighter/LinkHighlighter';
// import CsCustomer from './cs-customer/CsCustomer';
// import CloseDealModal from './close-deal/CloseDealModal';
// import HowToProceed from './item-render/HowToProceed';
// import AIGrading from './ai-grading/AiGrading';
import { IItem } from '../../interfaces/ai-agents/IItemsList';
import { socket } from '../../socket';
import AIGrading from './ai-grading/AiGrading';
import HowToProceed from './item-render/HowToProceed';
import Button from '../../shared/button';
import CloseDealModal from './close-deal/CloseDealModal';
import { TAgents } from '../../interfaces/ai-agents/TAgents';
// import CsCustomer from './cs-customer/CsCustomer';

const AiAgentsContent = ({
	type,
	setSocketId,
	socketId,
	items,
	setItems,
}: {
	type?: TAgents
	setSocketId: (socketId: string) => void,
	socketId: string,
	items: IItem[],
	setItems: (item: IItem) => void,
}) => {
	const [showCloseTheDealModal, setShowCloseTheDealModal] = useState<boolean>();
	const navigate = useNavigate();
	const location = useLocation();
	const dispatch = useAppDispatch();
	const { id } = useParams();
	const aiAgentsItems = useAppSelector(state => state.aiAgents) as IItemAiAgent;
	const chatContainerRef = useRef<HTMLDivElement>(null);

	const fetchConversations = async () => {
		try {
			const response = await dispatch(tryToFetchConversations({ brokerId: id || '', type })).unwrap();
			setSocketId(response?.conversation?._id || '');
			if (response.shouldCallNextQuestion)
				await dispatch(tryToAddNextConversationAiAgent({ chatId: id || '', type })).unwrap();
		} catch (error) {
			console.error({ error })
		}
	}
	useEffect(() => {
		const hasNavigated = sessionStorage.getItem('hasNavigated');
		if (hasNavigated && type && type !== 'ai-calling') {
			navigate('/ai-agents')
		}
		else {
			fetchConversations()
			sessionStorage.setItem('hasNavigated', 'true');
		}
		return () => {
			sessionStorage.removeItem('hasNavigated');
		};
	}, [location])

	useEffect(() => {
		if (chatContainerRef.current) {
			chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
		}
	}, [aiAgentsItems.items, items])

	socket.on('message', (data) => {
		console.log(`Message from ${data.from}: ${data.message}`, data);
		setItems(data);
	});

	const handleCloseTheDeal = () => setShowCloseTheDealModal(close => !close)

	return (
		<>
			<div id='chat-container' ref={chatContainerRef} className='block flex-col w-full max-h-[calc(100vh-200px)] mb-2 justify-end overflow-y-auto mt-auto'>
				{Array.isArray(aiAgentsItems.items) && aiAgentsItems.items?.map((item, index: number) => (
					<React.Fragment key={item.requestId + '-' + item.questionId + '-' + index}>
						{(String(item.message).includes('grading') && String(item.message).includes('Click the button to begin the process') && item.agent) ? <div key={item.message?.toString()} className='flex flex-row mb-4'>
							<img alt='photo' className={'w-10 h-10 mb-auto mr-4 rounded-full bg-gradient-to-br from-gradient-start to-gradient-end'} src={'/assets/ai-agents/agent.svg'} />

							<div className='w-[calc(100%-56px)] border rounded-xl p-5 break-words bg-white bg-opacity-50 border-[#E0E0E0] mb-4'>
								<AIGrading message={item.message} type={type} />
							</div>
						</div>
							// : item.buttons?.[0].toLowerCase().includes('close the deal') ? <>
							: String(item.buttons).toLowerCase().includes('close the deal') || String(item.message).includes('order confirmation number') ? <>
								{showCloseTheDealModal && <CloseDealModal socketId={socketId} showModal={showCloseTheDealModal} toggleShowModal={handleCloseTheDeal} />}
								<div key={item.message?.toString()} className='flex flex-row mb-4'>
									<img alt='photo' className={'w-10 h-10 mb-auto mr-4 rounded-full bg-gradient-to-br from-gradient-start to-gradient-end'} src={'/assets/ai-agents/agent.svg'} />
									<div className='w-[calc(100%-56px)] border rounded-xl p-5 break-words bg-white bg-opacity-50 border-[#E0E0E0] mb-4'>
										<p>{item.message}</p>
										{aiAgentsItems.userNeedsToAnswer ? <Button
											className="text-label text-sm py-3 px-7 rounded-xl bg-gradient-to-br from-gradient-start to-gradient-end hover:from-gradient-end hover:to-gradient-start mt-2"
											label={'Close the deal'}
											onClickButton={handleCloseTheDeal}
										/> : null}
									</div>
								</div>
							</> :
								<TransitionGroup>
									<CSSTransition key={item?.requestId + '-' + item?.questionId + '-' + index} appear={true} timeout={index === 1 ? 1000 : 200} classNames={(item.agent ? 'fadeInUp' : 'fadeInUpFast')}>
										<div key={item.message?.toString()} className='flex flex-row mb-4'>
											<img alt='photo' className={'w-10 h-10 mb-auto mr-4 rounded-full ' + (item.agent ? 'bg-gradient-to-br from-gradient-start to-gradient-end' : 'bg-black')} src={item.agent ? '/assets/ai-agents/agent.svg' : '/assets/ai-agents/my-profile.svg'} />
											<div className={'w-[calc(100%-56px)] border rounded-xl p-5 break-words bg-white bg-opacity-50 ' +
												(item.type === 'error' ? 'border-red-500 ' : 'border-[#E0E0E0]')
											}
											>
												{item.messageType === 'default' && <p>
													{(item.message.includes('how do you want to proceed?') && item.message.toLowerCase().includes('ai grading') && item.agent) ? <HowToProceed message={item.message} type={type} /> :
														<LinkHighlighter text={item.message} isLoading={aiAgentsItems.itemsAreLoading} type={type} buttons={item.buttons} stepDetails={item.stepDetails} />}
												</p>}
												{(item.messageType === 'file') && <FileItem file={item.file} message={item.message} />}
												{(item.messageType === 'inventory-details' && item.inventory) && <InventoryDetailsItem isLoading={aiAgentsItems.itemsAreLoading} message={item.message} inventoryDetails={item.inventory} userHasAcceptOrReject={aiAgentsItems.userHasAcceptOrReject} type={type} buttons={item.buttons} stepDetails={item.stepDetails} />}
												{(item.messageType === 'inventory-details' && item.logistics) && <LogisticDetailsItem isLoading={aiAgentsItems.itemsAreLoading} message={item.message} logisticDetails={item.logistics} type={type} userHasConfirmOrder={aiAgentsItems.userHasConfirmOrder} buttons={item.buttons} stepDetails={item.stepDetails} />}
												{(item.messageType === 'inventory-details' && (item.partialMatches || item.fullMatches)) && <MatchesDetailsItem isLoading={aiAgentsItems.itemsAreLoading} message={item.message} fullMatches={item.fullMatches} partialMatches={item.partialMatches} selectItemsToBuy={aiAgentsItems.selectItemsToBuy} type={type} stepDetails={item.stepDetails} />}
												{(item.messageType === 'inventory-details' && (item.getConfirmedItems)) && <GetConfirmationDetailsItem isLoading={aiAgentsItems.itemsAreLoading} message={item.message} getConfirmedItems={item.getConfirmedItems} confirmItemsToBuy={aiAgentsItems.confirmItemsToBuy} type={type} stepDetails={item.stepDetails} />}

											</div>
										</div>
									</CSSTransition>
								</TransitionGroup>}
						{item.type === 'pending' &&
							<TransitionGroup>
								<CSSTransition appear={true} timeout={300} classNames="fadeInUp">
									<div key={item.message?.toString()} className='flex flex-row mb-4'>
										<img alt='photo' className='w-10 h-10 mb-auto mr-4 rounded-full bg-gradient-to-br from-gradient-start to-gradient-end' src='/assets/ai-agents/agent.svg' />
										<div className='w-[calc(100%-56px)] border rounded-xl p-5 gradient-placeholder'>
											...
										</div>
									</div>
								</CSSTransition>
							</TransitionGroup>
						}
					</React.Fragment>
				))}
				{[...items || []]?.map((item, index: number) => (
					<React.Fragment key={item._id + '-' + item.sender + '-' + index}>
						<TransitionGroup>
							<CSSTransition key={item?._id + '-' + '-' + index} appear={true} timeout={index === 1 ? 1000 : 200} classNames={(item ? 'fadeInUp' : 'fadeInUpFast')}>
								<div key={item.message?.toString() || item.questionText?.toString()} className='flex flex-row mb-4'>
									<img alt='photo' className={'w-10 h-10 mb-auto mr-4 rounded-full ' + (item.sender ? 'bg-gradient-to-br from-gradient-start to-gradient-end' : 'bg-black')} src={item.questionText ? '/assets/ai-agents/agent.svg' : item.sender === 'me' ? '/assets/auth/main-photo-yellow.svg' : '/assets/ai-agents/my-profile.svg'} />
									<div className='w-[calc(100%-56px)] border rounded-xl p-5 break-words bg-white bg-opacity-50 '>
										<p>
											{item.questionText && <LinkHighlighter text={item.questionText} />}
											{item.message && <LinkHighlighter text={item.message} />}
										</p>

									</div>
								</div>
							</CSSTransition>
						</TransitionGroup>
					</React.Fragment>
				))}
				
				{/* {aiAgentsItems.realTimeCs && <div className='w-[calc(100%-56px)] border rounded-xl p-5 break-words bg-white bg-opacity-50 border-[#E0E0E0] mb-4'>
				 <CsCustomer message={'switch to customer support '} socketId={socketId} />
				 </div>} */}
			</div>
			{(!aiAgentsItems.items || (Array.isArray(aiAgentsItems.items) && aiAgentsItems.items.length === 0)) &&
				(aiAgentsItems.itemsAreLoading ?
					<>
						<div className='flex flex-row mb-4'>
							<img alt='photo' className='w-10 h-10 mb-auto mr-4 rounded-full' src='/assets/auth/main-photo-yellow.svg' />
							<div className='w-[calc(100%-56px)] border rounded-xl p-5 gradient-placeholder'>
								...
							</div>
						</div>
						<div className='flex flex-row mb-4'>
							<img alt='photo' className='w-10 h-10 mb-auto mr-4 rounded-full' src='/assets/auth/main-photo-yellow.svg' />
							<div className='w-[calc(100%-56px)] border rounded-xl p-5 gradient-placeholder'>
								...
							</div>
						</div>
					</> :
					<div className='flex w-full h-full justify-center items-center'>
						Cannot find chat with this session Id
					</div>
				)}
		</>
	)
}

export default AiAgentsContent;
