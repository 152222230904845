import React from 'react';
import Modal from '../../../shared/modal';
import CloseDealForm from './CloseDealForm';

const CloseDealModal = ({
	socketId,
	showModal,
	toggleShowModal
} : {
	socketId: string,
	showModal: boolean,
	toggleShowModal: () => void
}) => (
		<Modal
			open={showModal}
			onClose={toggleShowModal}
			showInRight
			title={'Close the deal'}
			contentContainerStyle={'w-full'}
		>
			<CloseDealForm handleCloseModal={toggleShowModal} />
		</Modal>
	)

export default CloseDealModal;