import React, { useState } from 'react';
import { IPaginationPayload } from '../../../../interfaces/shared/IPaginationPayload';
import { IPage } from '../../../../interfaces/models/models/paginated-response.model';
import Table from '../../../../shared/table';
import moment from 'moment';
import { ICallStats } from '../../../../interfaces/ai-agents/agents-call/IAgentCall';
import { CSVLink } from 'react-csv';
import { aiAgentService } from '../../../../store/brokers/ai-agents-call/aiAgentsCallApi';
import { useGetCurrentUserQuery } from '../../../../store/user/userApi';
import Loading from '../../../../shared/Loading';
import { classNames } from '../../../../utils/classNames';


interface IAssetLibraryTable {
    rows: ICallStats[];
    rowsLoading: boolean;
    paginationData?: IPage;
    setPaginationState: ({ pageNumber, pageSize }: IPaginationPayload) => void;
}

const CustomerCallsTable = ({
    rows,
    rowsLoading,
    paginationData,
    setPaginationState,
}: IAssetLibraryTable) => {
	const {data: user} = useGetCurrentUserQuery();
	const [downloadedRows, setDownloadedRows] = useState<string[][]>();
    const [tableColumns] = useState<Array<string>>(['Customer', 'Time Stamp', 'Customer no.', 'Status', 'Agent', 'Action'])
	const csvLink1 = React.useRef() as React.MutableRefObject<any>;

	const [isLoading, setIsLoading] = useState<boolean>();

	const handleDownload = async (id: string) => {
		setIsLoading(true)
		try {
			const {data} = await aiAgentService.tryToDownloadBatchPdf({
				callId: id, 
				brokerId: user?.id || '',
				// brokerId: 'a434ad47e8cf272488b5a63b',
			});
			setDownloadedRows(data.data)
			setIsLoading(false)
			setTimeout(()=>{
				csvLink1.current.link.click();
			}, 1000)
		} catch (e){
			console.log({e});
			setIsLoading(false)
		}
	}

    return (
        <Table
            columns={tableColumns}
            paginationData={paginationData}
            setPaginationState={setPaginationState}
            containerStyle={'mt-0'}>
            {(!tableColumns || rowsLoading) ?
                <>
                    {[...Array(4)].map((x, i) => {
                        return (
                            <tr key={i}>
                                {[...Array(tableColumns?.length || 1)].map((x, j) => {
                                    return (
                                        <td key={j} className='td-1 !min-w-[100%] text-center py-5'><span></span></td>
                                    )
                                })}
                            </tr>
                        )
                    })}
                </>
                : <>
                    {rows?.length > 0 && rows?.map((row, idx: number) => (
                            <tr key={idx} className='bg-white border-b hover:bg-gray-50'>
                                <td className='px-6 py-4'>{row?.customer || '--'}</td>
                                <td className='px-6 py-4'>{moment(row?.updatedAt).format('DD/MM/YYYY') || '--'}</td>
                                <td className='px-6 py-4'>{row?._id || '--'}</td>
                                <td className='px-6 py-4'>
									<div className={classNames('border px-5 py-2 w-[150px] rounded-lg text-sm text-center ', row?.status.toLowerCase() === 'waiting response' ? 'border-[#E50000] text-[#E50000]' : 'border-[#00B67A] text-[#00B67A]')}>
										{row.status}
									</div>
								</td>
                                <td className='px-6 py-4'>{'--'}</td>
                                <td className='px-6 py-4'>
                                    <div className='flex flex-row items-start'>
                                        <div onClick={() => row?._id && handleDownload(row?._id)}>
                                            {isLoading ? <Loading /> :
												<img 
													title={'download'} 
													alt='item details' 
													src={'/assets/table-actions/download.svg'} 
													className={`w-[20px] min-w-[20px] mr-3 mt-1 object-contain cursor-pointer`} 
												/>}
                                        </div>
										<CSVLink
											ref={csvLink1}
											data={downloadedRows?.filter((_item, idx: number) => idx > 0 ) || []}
											headers={downloadedRows?.[0] || []}
											className={'none'}
											target={'_blank'}
											filename={`Calls-transcripts.csv`}
										></CSVLink>
                                    </div>
                                </td>
                            </tr>
                        )
                    )}
                </>
            }
        </Table>
    )
}

export default CustomerCallsTable;