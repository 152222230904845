import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import Input from '../../shared/input';
import Button from '../../shared/button';
import { useForm } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { tryToAddConversationAiAgent, tryToAddNextConversationAiAgent } from '../../store/brokers/ai-agents/aiAgentsSlice';
import { IItemAiAgent } from '../../interfaces/ai-agents/IItem';
// import { useNavigate } from 'react-router-dom';
import { socket } from '../../socket';
import { IItem } from '../../interfaces/ai-agents/IItemsList';
import { TAgents } from '../../interfaces/ai-agents/TAgents';

const AiAgentsNewMessage = React.forwardRef(({ chatId, type, socketId, setItems }: { chatId: string, type?: TAgents, socketId: string, setItems: (item: IItem) => void }, ref) => {
	// const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const [debouncedQuery, setDebouncedQuery] = useState<string>('');
	const aiAgentsItems = useAppSelector(state => state.aiAgents) as IItemAiAgent;
	const fileUploadRef = useRef<HTMLInputElement>(null);
	const triggerFileUpload = () => fileUploadRef.current && fileUploadRef.current.click();
	const { register, setValue, handleSubmit, watch, reset } = useForm<{ message: string, file?: File }>();

	const onSubmit = async ({ message, file }: { message: string, file?: File }) => {
		reset({
			message: '',
			file: undefined
		})
		if (aiAgentsItems.realTimeCs) {
			try {
				socket.emit('message', { conversationId: socketId, message, file, id: 'broker' });
				setItems({ message, read: false, sender: 'me', createdAt: new Date().toISOString(), _id: 123 + '' })
			} catch (e) {
				console.error({ e })
			}
		} else {
			try {
				const addChat = await dispatch(tryToAddConversationAiAgent({ answer: message, files: file, chatId, type })).unwrap();
				// if (addChat.finalStep) {
				// const token = addChat.data || '';
				// if (token) {
				// localStorage.setItem('accessToken', token);
				// localStorage.setItem('roles', 'partners');
				// navigate('/insights')
				// }
				// }
				if (addChat.success && !addChat.finalStep) {
					await dispatch(tryToAddNextConversationAiAgent({ chatId, type })).unwrap();
				}
			} catch (e) {
				console.error({ e })
			}
		}

	}

	const handleFileChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
		target.files?.[0] && setValue('file', target.files[0])
		onSubmit(watch());
		reset({
			message: '',
			file: undefined
		})
	}

	const handleDropFile = (file: File) => {
		file && setValue('file', file)
		onSubmit(watch());
		reset({
			message: '',
			file: undefined
		})
	};

	React.useImperativeHandle(ref, () => ({
		handleDropFileChild: handleDropFile,
		handleFileChangeChild: handleFileChange,
		fileUploadRef
	}));

	useEffect(() => {
		const timer = setTimeout(() => {
			setDebouncedQuery(watch('message'));
		}, 500);
		return () => clearTimeout(timer);
	}, [watch('message')]);

	useEffect(() => {
		if (debouncedQuery) {
			socket.emit('typing', { conversationId: socketId, sender: 'broker' })
		}
	}, [debouncedQuery]);

	const isDisabled = aiAgentsItems.itemsAreLoading || !aiAgentsItems.userNeedsToAnswer;

	console.log({aiAgentsItems})

	return (
		<form onSubmit={handleSubmit(onSubmit)} className='flex flex-row w-full z-50'>
			<div className='w-full mr-2 relative'>
				<Input register={register('message')} placeholder='Send a message' containerStyle='w-full h-full' inputStyle='border border-[#E2E8F0] h-full rounded pr-8' />
				<button disabled={isDisabled || aiAgentsItems.answerType === 'text'} className={'bg-transparent absolute top-3 right-1 ' + ((isDisabled || aiAgentsItems.answerType === 'text') ? 'opacity-50 hover:opacity-50' : '')} onClick={triggerFileUpload} type='button'>
					<img alt='upload' src='/assets/ai-agents/upload.svg' />
				</button>
				<input type='file' className='hidden' onChange={handleFileChange} ref={fileUploadRef} id='file-upload-ai' />
			</div>
			<Button disabled={isDisabled} className={'btn-primary text-base font-medium rounded-lg md:min-w-[192px] py-0 ' + (isDisabled ? 'opacity-50 hover:opacity-50' : '')} label='Submit' type='submit' />
		</form>
	)
});

export default AiAgentsNewMessage;