import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { tryToFindOperatingMarkets } from '../../../store/brokers/admin/partners/partnersSlice';
import { useGetAllMarketsMutation, useUserProfileMutation } from '../../../store/user/userDomApi';
import PersonalInformation from './categories/PersonalInformation';
import Warehouses from './categories/Warehouses';
import Shipping from './categories/Shipping';
import Markets from './categories/Markets';
import Payment from './categories/Payment';
import Button from '../../../shared/button';
import AiChaserAgent from './ai-chaser-agent';


interface IProfileCategories {
    name: string,
    element?: React.ReactNode
}

const profileCategories: IProfileCategories[] = [
    { name: 'Markets' },
    { name: 'Warehouses' },
    // { name: 'Addresses' },
    // { name: 'Shipping' },
    // { name: 'Payment' },
    { name: 'Company Information' },
	{ name: 'AI Chaser Agent' }
]

const Profile = () => {
    const dispatch = useAppDispatch()
    const [activeCategory, setActiveCategory] = useState<IProfileCategories>()
    const [brokerMarkets, setBrokerMarkets] = useState<Array<any>>()
    const [allMarkets, setAllMarkets] = useState<Array<any>>()
    const [userProfile, setUserProfile] = useState<any>()
    const [pageAccess, setPageAccess] = useState<any>()
    const accessControl = useAppSelector((state) => state?.partners?.partnerNavigation);
    const [userProfileMutation] = useUserProfileMutation()
    const [getAllMarkets] = useGetAllMarketsMutation()

    useEffect(() => {
        const findPage = accessControl && accessControl?.length > 0 && accessControl?.find((item: any) => item?.name === 'Profile')
        const findChild = findPage && findPage?.children && findPage?.children?.length > 0 && findPage?.children?.find((item: any) => item?.path === '')
        setPageAccess(findChild || undefined)
        const findIfMarkets = (profileCategories && profileCategories?.length > 0) && profileCategories?.find((item) => findChild?.actions[item?.name] || !accessControl)
        if (findIfMarkets) {
            setActiveCategory(profileCategories[0])
        } else {
            const filterProfileCategories = profileCategories?.filter((item: any) => !accessControl || findChild?.actions?.[item?.name])
            setActiveCategory(filterProfileCategories?.[0])
        }
    }, [accessControl])

    const getUserProfileInfo = async () => {
        const response = await userProfileMutation(null).unwrap()
        setUserProfile(response?.data || undefined)
    }

    const getMarketsData = async () => {
        const response = await getAllMarkets(null).unwrap()
        setAllMarkets(response || [])
    }

    const getAllOperatingMarkets = async () => {
        const response = await dispatch(tryToFindOperatingMarkets(null)).unwrap()
        setBrokerMarkets(response || [])
    }

    useEffect(() => {
        getUserProfileInfo()
        getMarketsData()
        getAllOperatingMarkets()
    }, [])

    const onChangeActiveCategory = (item: IProfileCategories) => {
        setActiveCategory(item)
    }

    const onSuccessfullFinishMarketAction = (type: string, marketId: string) => {
        if (type === 'disable') {
            const formatBrokerMarkets = brokerMarkets?.filter((item) => item?.market?._id !== marketId)
            setBrokerMarkets(formatBrokerMarkets || [])
        } else {
            const findMarket = allMarkets?.find((market) => market?._id === marketId)
            setBrokerMarkets([
                ...brokerMarkets || [],
                { market: findMarket || {} }
            ])
        }
        getAllOperatingMarkets()
    }

    const onCallActiveElement = () => {
        switch (activeCategory?.name) {
            case 'Markets':
                return <Markets
                    onSuccessfullFinishAction={onSuccessfullFinishMarketAction}
                    allMarkets={allMarkets || []}
                    pageAccess={pageAccess}
                    accessControl={accessControl}
                    brokerMarkets={brokerMarkets || []}
                />
            case 'Warehouses':
                return <Warehouses
                    pageAccess={pageAccess}
                    accessControl={accessControl}
                    brokerMarkets={brokerMarkets || []}
                />
            // case 'Addresses':
            //     return <Addresses addressesData={userProfile?.addresses} />
            case 'Shipping':
                return <Shipping />
            case 'Payment':
                return <Payment />
            case 'Company Information':
                return <PersonalInformation
                    pageAccess={pageAccess}
                    accessControl={accessControl}
                    userInfo={userProfile}
                    getUserProfileInfo={getUserProfileInfo}
                />
			case 'AI Chaser Agent':
				return <AiChaserAgent />
        }
    }

    return (
        <div>
            <div className='mb-5'>
                <p className='page-title'>Profile</p>
            </div>
            <div className='flex flex-row items-center my-5'>
                {profileCategories?.filter((item: any) => !accessControl || pageAccess?.actions?.[item?.name])?.map((category, index) => {
                    return (
                        <Button
                            key={index}
                            label={<span className={`${activeCategory?.name === category?.name ? 'text-[#F7A21E]' : 'text-[#c2c6cc]'} text-[15px]`}>{category?.name}</span>}
                            dataQa={`${category?.name}`}
                            className={activeCategory?.name === category?.name ? 'btn-categories ml-2' : 'btn-noactive-categories ml-2'}
                            onClickButton={() => onChangeActiveCategory(category)}
                        />
                    )
                })}
            </div>
            <div className='my-2 min-w-[100%]'>
                {onCallActiveElement()}
            </div>
        </div>

    )
}

export default Profile;