import React, { useCallback, useState } from 'react';
import { IInventoryDetails } from '../../../interfaces/ai-agents/IItem';
import Button from '../../../shared/button';
import { useNavigate, useParams } from 'react-router-dom';
import { tryToAddConversationAiAgent, tryToAddNextConversationAiAgent } from '../../../store/brokers/ai-agents/aiAgentsSlice';
import { useAppDispatch } from '../../../app/hooks';
import { TAgents } from '../../../interfaces/ai-agents/TAgents';

const InventoryDetailsItem = ({
	message,
	inventoryDetails,
	isLoading,
	type,
	userHasAcceptOrReject,
	buttons,
	stepDetails
}: {
	message: string,
	inventoryDetails: IInventoryDetails,
	isLoading: boolean,
	type?: TAgents,
	userHasAcceptOrReject?: boolean,
	buttons?: string[],
	stepDetails?: {stepNr: number, stepAnswer: string}
}) => {
	const navigate = useNavigate();
	const token = localStorage.getItem('accessToken')
	const {id} = useParams();
	const dispatch = useAppDispatch();
	const [showTable, setShowTable] = useState<boolean>();
	const handleToggleTable = useCallback(() => setShowTable(table => !table), [showTable, setShowTable]);

	const handleGoToAssets = useCallback(async () => {
		navigate('/asset-library')
 	}, [id]);

	const handleClick = useCallback(async (answer: string) => {
		if (answer === 'asset library') {
			handleGoToAssets()
		} else {
			const addChat = await dispatch(tryToAddConversationAiAgent({ answer, chatId: id || '', type })).unwrap();
			if (addChat.success && !addChat.finalStep) {
				await dispatch(tryToAddNextConversationAiAgent({chatId: id || ''})).unwrap();
			}
		}
		
 	}, [id]);

	const handleAccept = useCallback(async () => {
		const addChat = await dispatch(tryToAddConversationAiAgent({ answer: 'Accept all', chatId: id || '', type, userHasAcceptOrReject: true })).unwrap();
		if (addChat.success && !addChat.finalStep) {
			await dispatch(tryToAddNextConversationAiAgent({chatId: id || ''})).unwrap();
		}
 	}, [id]);

	return (
		<div className='w-full'>
			<p>{message}</p>
			<div className='flex flex-wrap my-4'>
				<div className='w-full md:w-4/12 px-2 mb-2 md:mb-0'>
					<div className='py-3 px-5 rounded-2xl bg-primary-default bg-opacity-20 h-full flex flex-col justify-center'>
						<h4 className='text-[#484A4B] font-bold text-2xl md:text-4xl'>{inventoryDetails?.models}</h4>
						<p className='text-sm font-normal text-[#484A4B]'>{inventoryDetails?.models === 1 ? 'Model' : 'Models'}</p>
					</div>
				</div>
				<div className='w-full md:w-4/12 px-2 mb-2 md:mb-0'>
					<div className='py-3 px-5 rounded-2xl bg-primary-default bg-opacity-20 h-full flex flex-col justify-center'>
						<h4 className='text-[#484A4B] font-bold text-2xl md:text-4xl'>
							{inventoryDetails?.phoneUploaded}
						</h4>
						<p className='text-sm font-normal text-[#484A4B]'>Phone Uploaded</p>
					</div>
				</div>
				<div className='w-full md:w-4/12 px-2 mb-2 md:mb-0'>
					<div className='py-3 px-5 rounded-2xl bg-primary-default bg-opacity-20 h-full flex flex-col justify-center'>
						<h4 className='text-[#484A4B] font-bold text-2xl md:text-4xl'>
							{new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' }).format(inventoryDetails?.estimatedValue || 0)}
						</h4>
						<p className='text-sm font-normal text-[#484A4B]'>Estimated Values</p>
					</div>
				</div>
			</div>
			<button onClick={handleToggleTable} className='bg-transparent underline text-primary-default mb-4' type='button'>
				Show Offer Details for the current inventory.
			</button>
			{showTable && (
				<div className='w-full px-2'>
					<table className='w-full rounded-lg'>
						<tbody>
							{inventoryDetails.inventory?.map((inventory, index: number) =>
								<tr key={inventory.descriptor} >
									<td className='bg-[#F8F9FC] p-2 border-b border-dashed text-sm'>{index + 1}</td>
									<td className='bg-[#F8F9FC] p-2 border-b border-dashed text-sm'>{inventory.make} {inventory.model} {inventory.storage},  {inventory.condition}, {inventory.network}</td>
									<td className='bg-[#F8F9FC] p-2 border-b border-dashed text-sm'>{inventory.quantity}</td>
									<td className='bg-[#F8F9FC] p-2 border-b border-dashed text-sm text-[#8A8A8E]'>
										{new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' }).format(inventory.price)}
									</td>
								</tr>
							)}
						</tbody>
						<tfoot>
							<tr>
								{ buttons ? stepDetails?.stepAnswer ? <p className='text-[#00B67A] text-sm font-normal'>{stepDetails.stepAnswer}</p> :  buttons.map((button) => 
									!(button === 'Asset library' && !token) && <td key={button} className='pt-5'>
										<Button 
											disabled={isLoading}
											loading={isLoading}
											className="text-label text-sm py-3 px-7 rounded-xl bg-gradient-to-br from-gradient-start to-gradient-end hover:from-gradient-end hover:to-gradient-start mx-2"
											label={
												button === 'accept all' && inventoryDetails.inventory.length === 1 ? 'accept' :
												button === 'reject all' && inventoryDetails.inventory.length === 1 ? 'reject' : button
											}
											onClickButton={() => handleClick(button?.toLowerCase()) }
										/>
								</td>
								) :
								<>
								<td className='pt-5'>
									{!userHasAcceptOrReject && <Button 
										disabled={isLoading}
										loading={isLoading}
										// className='text-[#00B67A] text-sm font-normal hover:underline' 
										className="text-label text-sm py-3 px-7 rounded-xl bg-gradient-to-br from-gradient-start to-gradient-end hover:from-gradient-end hover:to-gradient-start mx-2"
										label='Accept All Offers' 
										onClickButton={handleAccept}
									/>}
								</td>
								<td className='pt-5'>
									{!userHasAcceptOrReject && <Button 
										disabled={isLoading} 
										loading={isLoading}
										// className='text-[#E50000] text-sm font-normal hover:underline' 
										className="text-label text-sm py-3 px-7 rounded-xl bg-gradient-to-br from-gradient-start to-gradient-end hover:from-gradient-end hover:to-gradient-start mx-2"
										label='Go to Asset Library' 
										onClickButton={handleGoToAssets}
									/>}
								</td>
								</>}
							</tr>
						</tfoot>
					</table>
				</div>
			)}
		</div>
	)
}

export default InventoryDetailsItem;