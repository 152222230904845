import React from 'react'
import Modal from '../../../../shared/modal';
import Button from '../../../../shared/button';


interface IDownloadPricingModal {
    openModal: boolean;
    accessControl?: any;
    actions?: any;
    loadingDownload?: boolean;
    onDownloadPricing: (excel: boolean) => void;
    handleClose: () => void;
}

const DownloadPricingModal = ({
    openModal,
    accessControl,
    actions,
    loadingDownload,
    handleClose,
    onDownloadPricing,
}: IDownloadPricingModal) => {
    return (
        <Modal
            open={openModal}
            showInRight
            onClose={handleClose}
            contentContainerStyle={'!min-w-[650px]'}>
            <div className={'p-2 min-w-[600px]'}>
                <div className='border-b-2 border-slate-200 pb-2 my-1'>
                    <p className='mb-2 font-semibold text-sm'>Download Pricing</p>
                </div>
                <div className='my-5'>
                    <p className='mb-10 text-center'>Download Pricing Excel or CSV</p>
                    <div className='flex flex-row justify-center items-center my-5'>
                        <Button
                            label={'Download Excel'}
                            className={`${!loadingDownload ? 'btn-main' : 'btn-main-disable'} !rounded !min-w-[120px] !shadow-none mr-8`}
                            onClickButton={() => (!accessControl || actions?.['Download Pricing']) && onDownloadPricing(true)}
                        />
                        <Button
                            label={'Download CSV'}
                            className={`${!loadingDownload ? 'btn-primary' : 'btn-main-disable'} !rounded !min-w-[120px] !shadow-none`}
                            onClickButton={() => (!accessControl || actions?.['Download Pricing']) && onDownloadPricing(false)}
                        />
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default DownloadPricingModal;