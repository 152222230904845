import React, { useEffect, useRef, useState } from 'react';
import AiAgentsNewMessage from './AiAgentsNewMessage';
import AiAgentsContent from './AiAgentsContent';
import { useLocation, useParams } from 'react-router-dom';
import { onLogEvent } from '../../utils/googleAnalytics';
import { useGetCurrentUserQuery } from '../../store/user/userApi';
import { IItem } from '../../interfaces/ai-agents/IItemsList';
import { TAgents } from '../../interfaces/ai-agents/TAgents';

const AiAgents = ({
	type
}: {
	type?: TAgents
}) => {
	const { data } = useGetCurrentUserQuery();
	const [socketId, setSocketId] = useState<string>('');
	const [showUpload, setShowUpload] = useState<boolean>(false)

	const [items, setItems] = useState<IItem[]>([])

	const handleAddItem = (item: IItem) => setItems([...items, item])
	const childRef = useRef<any>(null);
	const { id } = useParams();
	const location = useLocation()

	const handleSetSocketId = (socketId: string) => setSocketId(socketId);

	useEffect(() => {
		onLogEvent(id || '', data?.username || '', location.pathname);
	}, [])

	const handleDrop = (event: any) => {
		event.preventDefault();
		const files = Array.from(event.dataTransfer.files);
		if (childRef.current && files?.[0]) {
			childRef.current.handleDropFileChild(files[0]);
			setShowUpload(false);
		}
	};

	const handleDragOver = (event: any) => {
		event.preventDefault();
		!showUpload && setShowUpload(true);
	};

	const handleDragLeave = () => {
		showUpload && setShowUpload(false);
	};

	useEffect(()=> {
		if (type === 'ai-calling' || !type) {
			localStorage.removeItem('accessToken')
		}
	}, [type])

	return (
		<div className='w-full ai-bg bg-no-repeat bg-center relative h-full'>
			<div
				onDragOver={handleDragOver}
				onDragLeave={handleDragLeave}
				onDrop={handleDrop}
				className={'flex flex-col h-[calc(100vh-250px)] ' + (showUpload ? 'pointer border-2 border-dashed bg-black bg-opacity-10' : '')}
			>
				<AiAgentsContent type={type} setSocketId={handleSetSocketId} socketId={socketId} items={items} setItems={handleAddItem} />
				<input type='file' className='hidden' onChange={childRef.current?.handleFileChangeChild} ref={childRef.current?.fileUploadRef} id='file-upload-ai' />
			</div>
			<AiAgentsNewMessage chatId={id || ''} ref={childRef} type={type} socketId={socketId} setItems={handleAddItem} />
		</div>

	)
}

export default AiAgents;
