import React, { useEffect, useState } from 'react';
import { CSVLink } from 'react-csv';
import ExcelJS from 'exceljs';
import { usePricingTemplateMutation } from '../../../../store/brokers/partners/download-templates/downloadTemplatesApi';
import SelectCheckbox from '../../../../shared/select-checkbox';
import Button from '../../../../shared/button';
import Modal from '../../../../shared/modal';
import Error from '../../../../shared/error';


interface IDownloadSampleModal {
    openDownloadModal: boolean;
    sectors: Array<any>;
    handleCloseDownloadModal: () => void;
}

const DownloadSampleModal = ({
    openDownloadModal,
    sectors,
    handleCloseDownloadModal,
}: IDownloadSampleModal) => {
    const csvLink = React.useRef() as React.MutableRefObject<any>;
    const [selectedSector, setSelectedSector] = useState<any>()
    const [downloadError, setDownloadError] = useState<string>()
    const [loadingDownload, setLoadingDownload] = useState<boolean>(false)
    const [pricingTemplateData, setPricingTemplateData] = useState<Array<any>>()
    const [partnerPricingTemplateMutation] = usePricingTemplateMutation()

    const onChangeSelectedSector = (e: any) => {
        setSelectedSector(e)
    }

    const onCloseModal = () => {
        handleCloseDownloadModal();
        setSelectedSector(undefined)
        setDownloadError(undefined)
        setLoadingDownload(false)
        setPricingTemplateData(undefined)
    }

    const columnNumberToLetter = (colNum: number) => {
        let letter = '';
        while (colNum > 0) {
            const remainder = (colNum - 1) % 26;
            letter = String.fromCharCode(65 + remainder) + letter;
            colNum = Math.floor((colNum - 1) / 26);
        }
        return letter;
    };

    const formatString = (item: string) => {
        return item
            .replace(/_/g, ' ')
            .toLowerCase()
            .split(' ')
            .map((word: any) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    }

    const downloadExcel = async (data?: any) => {
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Styled Sheet');

        const columnCount = data?.[0]?.length > 0 ? data?.[0]?.length : 1
        for (let i = 1; i <= columnCount; i++) {
            worksheet.getColumn(i).width = 13;
            const cell = worksheet.getCell(`${columnNumberToLetter(i)}1`);
            cell.value = data?.[0]?.[i - 1] ? formatString(data?.[0]?.[i - 1]) : data?.[0]?.[i - 1];
            cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: '202020' },
            };
            cell.border = {
                left: { style: 'thin', color: { argb: 'FFFFFFFF' } }
            };
            cell.alignment = {
                vertical: 'middle',
                horizontal: 'center'
            };
            cell.font = {
                color: { argb: 'FFFFFF' },
                name: 'Avenir',
                bold: true,
                size: 12
            };
        }
        data?.slice(1).forEach((row: any, rowIndex: number) => {
            row?.forEach((cellValue: any, colIndex: number) => {
                const cell = worksheet.getCell(`${columnNumberToLetter(colIndex + 1)}${rowIndex + 2}`);
                cell.value = cellValue;
                cell.alignment = { vertical: 'middle', horizontal: 'center' };
            });
        });
        workbook.xlsx.writeBuffer().then((buffer: any) => {
            const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.download = `Pricing-${selectedSector?.name || 'catalog'}-sample.xlsx`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(url);
        });
        setLoadingDownload(false)
        onCloseModal()
    };

    const onDownloadTemplate = async (excel: boolean) => {
        if (loadingDownload) {
            return;
        }
        setLoadingDownload(true)
        if (!selectedSector) {
            setDownloadError('Please select a sector first to continue with download.')
            setLoadingDownload(false)
            return;
        }
        try {
            const response = await partnerPricingTemplateMutation({ assetTemplateId: selectedSector?.value || '' }).unwrap()
            const format = response && response?.length > 0 && response?.map((item: any, index: number) => {
                if (index !== 0) {
                    const formatArrayItem: any = item && item?.length > 0 && item?.map((element: any, idx: number) => {
                        if (idx + 1 !== item?.length && /^\d+$/.test(element) && (element?.length > 6)) {
                            return element + '-Wing';
                        } else {
                            return element
                        }
                    })
                    return formatArrayItem
                } else {
                    return item
                }
            })
            if (excel) {
                downloadExcel(format || [])
            } else {
                setPricingTemplateData(format || [])
            }
            setDownloadError(undefined)
        } catch (err: any) {
            setDownloadError(`${err?.response || err?.data?.message || err}`)
            setLoadingDownload(false)
        }
    }

    useEffect(() => {
        if (pricingTemplateData && loadingDownload) {
            csvLink.current.link.click()
            onCloseModal()
        }
        setLoadingDownload(false)
    }, [pricingTemplateData])

    return (
        <Modal
            open={openDownloadModal}
            showInRight
            onClose={onCloseModal}>
            <div className={'p-2 min-w-[550px]'}>
                <div className='border-b-2 border-slate-200 pb-2 my-1'>
                    <p className='mb-2 font-semibold text-sm'>Catalog</p>
                </div>
                <p className='mb-4 text-sm'>Please select a category to download catalog.</p>
                {downloadError && <Error text={downloadError} />}
                <div className='flex flex-col items-center'>
                    <SelectCheckbox
                        name='Category'
                        dataQa={'category'}
                        placeholder=' '
                        containerStyle='mb-1 min-w-full'
                        selectedOption={selectedSector}
                        onChangeSelectedOption={onChangeSelectedSector}
                        options={sectors || []}
                    />
                    <div className='flex flex-row justify-center items-center my-9'>
                        <Button
                            label={'Download Excel Catalog'}
                            dataQa={loadingDownload ? 'loading-data...' : 'download-catalog'}
                            onClickButton={() => !loadingDownload && onDownloadTemplate(true)}
                            disabled={loadingDownload}
                            className={`${loadingDownload ? 'btn-main-disable' : 'btn-main'} !shadow-none mr-5`}
                        />
                        <Button
                            label={'Download CSV Catalog'}
                            dataQa={loadingDownload ? 'loading-data...' : 'download-catalog'}
                            onClickButton={() => !loadingDownload && onDownloadTemplate(false)}
                            disabled={loadingDownload}
                            className={`${loadingDownload ? 'btn-main-disable' : 'btn-main'} !shadow-none`}
                        />
                    </div>
                    <CSVLink
                        ref={csvLink}
                        data={pricingTemplateData && pricingTemplateData?.length > 0 ? pricingTemplateData?.filter((item, idx) => idx > 0) : []}
                        headers={pricingTemplateData?.[0] || []}
                        className={'none'}
                        target={'_blank'}
                        filename={`Pricing-${selectedSector?.name || 'catalog'}-sample.csv`}
                    >
                    </CSVLink>
                </div>
            </div>
        </Modal >
    )
}
export default DownloadSampleModal;