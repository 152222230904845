import React, { useEffect } from 'react';
import CallRules from './CallRules';
import BatchInformation from './BatchInformation';
import CustomerCall from './CustomerCall';
import { useAppDispatch } from '../../../../app/hooks';
import { tryToGetBatches } from '../../../../store/brokers/ai-agents-call/aiAgentsCallSlice';
import { useGetCurrentUserQuery } from '../../../../store/user/userApi';

const AiChaserAgent = () => {
	const {data: user} = useGetCurrentUserQuery();
	const dispatch = useAppDispatch();
	useEffect(() => {
		dispatch(tryToGetBatches({brokerId: user?.id || ''}))
	}, [])
	return (
		<div className="">
			<CallRules />
			<BatchInformation />
			<CustomerCall />
		</div>
	)
}

export default AiChaserAgent;