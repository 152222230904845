import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { aiAgentService } from './aiAgentsApi';
import { IItemAiAgent, IItemAiAgentItem, IMatches } from '../../../interfaces/ai-agents/IItem';
import { AiAgentSession, INextQuestions } from '../../../interfaces/ai-agents/aiAgentSession';
import { IShippingInformationProps } from '../../../interfaces/ai-agents/IShippingInformation';
import { TAgents } from '../../../interfaces/ai-agents/TAgents';

const initialState: IItemAiAgent = {
	itemsAreLoading: false,
	groupedSuccessAnswers: [],
	items: [],
	shouldCallNextQuestion: false,
	userNeedsToAnswer: true,
	userHasAcceptOrReject: false,
	answerType: 'text',
	shippingAddress: null,
	realTimeCs: false
};

export const tryToFetchConversations = createAsyncThunk<
	AiAgentSession,
	{ brokerId: string, type?: TAgents }
>('aiAgents/tryToFetchConversations', async ({ brokerId = '', type }) => {
	const result = await aiAgentService.tryToFetchConversations(
		brokerId,
		type
	);
	return result?.data;
});

export const tryToCreateSessionConversations = createAsyncThunk<
	AiAgentSession,
	{ type: TAgents, sessionId: string }
>('aiAgents/tryToCreateSessionConversations', async ({ sessionId, type }) => {
	const result = await aiAgentService.tryToCreateSessionConversations(
		sessionId,
		type
	);
	return result?.data;
});

export const tryToAddConversationAiAgent = createAsyncThunk<{ message: string, userAnswer: boolean, isValid: boolean, success?: boolean, finalStep?: boolean, data?: string }, { chatId: string, answer: string, customMessage?: string, files?: File, type?: TAgents, userHasAcceptOrReject?: boolean, userHasConfirmOrder?: boolean, selectItemsToBuy?: boolean, confirmItemsToBuy?: boolean }>(
	'aiAgents/tryToAddConversationAiAgent',
	async (data) => {
		try {
			const response = await aiAgentService.tryToAddConversation(data);
			return response.data
		} catch (e) {
			const error = e as { response: { data: { message: string } } };
			throw new Error(error.response.data.message);
		}
	},
);

export const tryToAddNextConversationAiAgent = createAsyncThunk<INextQuestions, { chatId: string, type?: TAgents }>(
	'aiAgents/tryToAddNextConversationAiAgent',
	async ({ chatId, type }) => {
		const result = await aiAgentService.tryToAddNextConversation(chatId, type);
		return result.data
	},
);

export const tryToDownloadConfirmedItems = createAsyncThunk<any, IMatches[]>(
	'aiAgents/tryToDownloadConfirmedItems',
	async (data) => {
		const result = await aiAgentService.tryToDownloadConfirmedItems(data);
		return result.data;
	},
);

export const tryToRequestSupport = createAsyncThunk<any, {conversationId: string, userId: string}>(
	'aiAgents/tryToRequestSupport',
	async ({conversationId, userId}) => {
		const result = await aiAgentService.tryToRequestSupport({conversationId, userId});
		return result.data;
	},
);
export const tryToGetAllConversations = createAsyncThunk<any, any>(
	'aiAgents/tryToGetAllConversations',
	async ({ page, size }) => {
		const result = await aiAgentService.tryToGetAllConversations({ page, size });
		console.log({result})
		return result;
	},
);

export const tryToCloseTheDeal = createAsyncThunk<any, {conversationId: string, data: IShippingInformationProps}>(
	'aiAgents/tryToCloseTheDeal',
	async ({conversationId, data}) => {
		const result = await aiAgentService.tryToCloseTheDeal({conversationId, data});
		return result.data;
	},
);

export const tryToGetOnboardingShippingAddress = createAsyncThunk<any, string>(
	'aiAgents/tryToGetOnboardingShippingAddress',
	async (id) => {
		const result = await aiAgentService.tryToGetOnboardingShippingAddress(id);
		return result.data;
	},
);


export const aiAgentsSlice = createSlice({
	name: 'aiAgents',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			// tryToCreateSessionConversations
			.addCase(tryToCreateSessionConversations.fulfilled, (state) => {
				state.itemsAreLoading = false;
				state.userNeedsToAnswer = true;
				state.userHasAcceptOrReject = false;
				state.userHasConfirmOrder = false;
				state.selectItemsToBuy = false;
				state.confirmItemsToBuy = false;
			})

			// tryToFetchConversations
			.addCase(tryToFetchConversations.pending, (state, action) => {
				state.itemsAreLoading = true;
			})
			.addCase(tryToFetchConversations.rejected, (state) => {
				state.itemsAreLoading = false;
			})
			.addCase(tryToFetchConversations.fulfilled, (state, action) => {
				state.itemsAreLoading = false;
				const history: IItemAiAgentItem[] = []
				const conversations = action.payload.data?.createSession?.conversation || action.payload.conversation.conversation;
				conversations?.forEach(conversation => {
					history.push({ questionId: conversation.questionId, message: conversation.questionText, messageType: 'default', type: 'success', agent: true })
					if (conversation.userAnswer) {
						const message = conversation.userAnswer;
						if (conversation.data) {
							history.push(
								{
									questionId: conversation.questionId, message: conversation.question, messageType: 'inventory-details', type: 'success', agent: true,
									...(conversation.data.logisticsDetails ? { logistics: conversation.data } : { inventory: conversation.data })
								}
							)
						} else {
							history.push({ questionId: conversation.questionId, message, messageType: conversation.answerType === 'file' ? 'file' : 'default', type: 'success' })
						}
					}
				})
				state.items = history;
				state.shouldCallNextQuestion = action.payload.shouldCallNextQuestion;
				// state.userNeedsToAnswer = action.payload.shouldCallNextQuestion;
			})

			// tryToAddConversationAiAgent
			.addCase(tryToAddConversationAiAgent.pending, (state, action) => {
				const item: IItemAiAgentItem = { ...action.meta.arg, requestId: action.meta.requestId, message: action.meta.arg.customMessage || action.meta.arg.answer, messageType: 'default', type: 'pending' }
				state.items = state.items?.map((item, index) => (index === Number(state.items?.length) - 1) ? {...item, stepDetails: {  stepNr: item.stepDetails?.stepNr || 0, stepAnswer: action.meta.arg.answer || '' }} : item)
				if (state.items && action.meta.arg.answer) {
					state.items = [...state.items || [], { ...item, messageType: 'default' }];
				} else if (state.items && action.meta.arg.files) {
					state.items = [...state.items || [], { ...item, file: action.meta.arg.files, messageType: 'file' }];
				}
				if (action.meta.arg.userHasAcceptOrReject !== undefined) state.userHasAcceptOrReject = Boolean(action.meta.arg.userHasAcceptOrReject);
				else if (action.meta.arg.userHasConfirmOrder !== undefined) state.userHasConfirmOrder = Boolean(action.meta.arg.userHasConfirmOrder);
				else if (action.meta.arg.selectItemsToBuy !== undefined) state.selectItemsToBuy = Boolean(action.meta.arg.selectItemsToBuy);
				else if (action.meta.arg.confirmItemsToBuy !== undefined) state.confirmItemsToBuy = Boolean(action.meta.arg.confirmItemsToBuy);

				state.itemsAreLoading = true;
			})
			.addCase(tryToAddConversationAiAgent.rejected, (state, action) => {
				state.itemsAreLoading = false;
				const prevState = state.items?.map(item => item.requestId === action.meta.requestId ? { ...item, type: 'error' as 'error' } : item) || [];
				state.items = [...prevState, { message: action.error.message || '', messageType: 'default', type: 'error', agent: true }];
			})
			.addCase(tryToAddConversationAiAgent.fulfilled, (state, action) => {
				state.itemsAreLoading = false;
				const falseResponse = action.payload.message;
				if (action.payload.finalStep) {
					state.userNeedsToAnswer = false;
					const prevState = state.items?.map(item => item.requestId === action.meta.requestId ? { ...item, type: 'success' as 'success' } : item) || [];
					state.items = [...prevState];
				}
				if (falseResponse) {
					const prevState = state.items?.map(item => item.requestId === action.meta.requestId ? { ...item, type: 'success' as 'success' } : item) || [];
					state.items = [...prevState, { message: action.payload.message, messageType: 'default', type: 'success', agent: true }];
				}
			})

			// tryToAddNextConversationAiAgent
			.addCase(tryToAddNextConversationAiAgent.pending, (state, action) => {
				state.itemsAreLoading = true;
			})
			.addCase(tryToAddNextConversationAiAgent.rejected, (state, action) => {
				state.itemsAreLoading = false;
				const prevState = state.items?.map(item => item.type === 'pending' ? { ...item, type: 'error' as 'error' } : item) || [];
				state.items = [...prevState]
			})
			.addCase(tryToAddNextConversationAiAgent.fulfilled, (state, action) => {
				state.itemsAreLoading = false;
				state.answerType = action.payload.answerType;
				const prevState = state.items?.map(item => item.type === 'pending' ? { ...item, type: 'success' as 'success' } : item) || [];
				state.items = [...prevState,
				action.payload.data ?
					{
						...action.payload, stepDetails: {stepNr: action.payload.step, stepAnswer: ''}, questionId: action.payload.questionId, message: action.payload.question, messageType: 'inventory-details', type: 'success', agent: true,
						...(action.payload.data.logisticsDetails ? { logistics: action.payload.data } :
							action.payload.data.inventory ? { inventory: action.payload.data } : action.payload.data.getConfirmedItems ?
								{ getConfirmedItems: action.payload.data.getConfirmedItems } :
								{ partialMatches: action.payload.data.partialMatches, fullMatches: action.payload.data.fullMatches }),
					} : { ...action.payload, stepDetails: {stepNr: action.payload.step, stepAnswer: ''}, questionId: action.payload.questionId, message: action.payload.question, messageType: 'default', type: 'success', agent: true }
				];
			})

			// tryToDownloadConfirmedItems
			.addCase(tryToDownloadConfirmedItems.pending, (state, action) => {
				state.itemsAreLoading = true;
			})
			.addCase(tryToDownloadConfirmedItems.rejected, (state, action) => {
				state.itemsAreLoading = false;
			})
			.addCase(tryToDownloadConfirmedItems.fulfilled, (state, action) => {
				state.itemsAreLoading = false;
			})

			// tryToDownloadConfirmedItems
			.addCase(tryToCloseTheDeal.pending, (state, action) => {
				state.itemsAreLoading = true;
			})
			.addCase(tryToCloseTheDeal.rejected, (state, action) => {
				state.itemsAreLoading = false;
			})
			.addCase(tryToCloseTheDeal.fulfilled, (state, action) => {
				state.itemsAreLoading = false;
				// state.userNeedsToAnswer = false;
			})

			// tryToGetOnboardingShippingAddress
			.addCase(tryToGetOnboardingShippingAddress.pending, (state, action) => {
				state.itemsAreLoading = true;
			})
			.addCase(tryToGetOnboardingShippingAddress.rejected, (state, action) => {
				state.itemsAreLoading = false;
			})
			.addCase(tryToGetOnboardingShippingAddress.fulfilled, (state, action) => {
				state.itemsAreLoading = false;
				state.shippingAddress = action.payload.data
			})

			.addCase(tryToRequestSupport.fulfilled, (state, action) => {
				state.realTimeCs = true
			})

	},
});

export default aiAgentsSlice.reducer;
