import React, { useState, useEffect } from 'react';
import { CSVLink } from 'react-csv';
import ExcelJS from 'exceljs';
import { useAppDispatch } from '../../../../app/hooks';
import { IPricing } from '../../../../interfaces/pricing/IPricing';
import { IAllMarkets } from '../../../../interfaces/markets/IAllMarkets';
import { IPageAccess } from '../../../../interfaces/components/IPageAccess';
import { IAccessControl } from '../../../../interfaces/components/IAccessControl';
import { IPage } from '../../../../interfaces/models/models/paginated-response.model';
import { IPaginationPayload } from '../../../../interfaces/shared/IPaginationPayload';
import { IAssetTemplate } from '../../../../interfaces/asset-templates/IAssetTemplate';
import { usePricingTemplateMutation } from '../../../../store/brokers/partners/download-templates/downloadTemplatesApi';
import { tryToDownloadPricingAdmin } from '../../../../store/brokers/partners/pricing/pricingSlice';
import ImportPricingCsvModal from '../modals/ImportPricingCsvModal';
import DownloadPricingModal from '../modals/DownloadPricingModal';
import DownloadSampleModal from '../modals/DownloadSampleModal';
import AdminPricingTable from '../tables/AdminPricingTable';
import Button from '../../../../shared/button';


interface IDetailsStep {
    rows: IPricing[];
    paginationData?: IPage;
    markets?: IAllMarkets[];
    sectors?: IAssetTemplate[];
    pricingLoading: boolean;
    state: any;
    accessControl?: IAccessControl;
    pageAccess?: IPageAccess;
    marketSelected?: any;
    templateSelected?: any;
    partnerSelected?: any;
    setPaginationState: ({ pageNumber, pageSize }: IPaginationPayload) => void;
}

const DetailsStep = ({
    rows,
    paginationData,
    markets,
    state,
    accessControl,
    pageAccess,
    marketSelected,
    templateSelected,
    pricingLoading,
    sectors,
    partnerSelected,
    setPaginationState,
}: IDetailsStep) => {
    const dispatch = useAppDispatch();
    const csvLink = React.useRef() as React.MutableRefObject<any>;
    const csvLink2 = React.useRef() as React.MutableRefObject<any>;
    const [dataPricing, setDataPricing] = useState<any>();
    const [templateData, setTemplateData] = useState<any>();
    const [loadingDownload, setLoadingDownload] = useState<boolean>(false);
    const [showImportCsvModal, setShowImportCsvModal] = useState<boolean>(false);
    const [loadingDownloadPricing, setLoadingDownloadPricing] = useState<boolean>(false);
    const [showDownloadSampleModal, setShowDownloadSampleModal] = useState<boolean>(false);
    const [showDownloadPricingModal, setShowDownloadPricingModal] = useState<boolean>(false);
    const [partnerPricingTemplateMutation] = usePricingTemplateMutation();

    const columnNumberToLetter = (colNum: number) => {
        let letter = '';
        while (colNum > 0) {
            const remainder = (colNum - 1) % 26;
            letter = String.fromCharCode(65 + remainder) + letter;
            colNum = Math.floor((colNum - 1) / 26);
        }
        return letter;
    };

    const formatString = (item: string) => {
        return item
            .replace(/_/g, ' ')
            .toLowerCase()
            .split(' ')
            .map((word: any) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    }

    const downloadExcel = async (data?: any) => {
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Styled Sheet');

        const columnCount = data?.[0]?.length > 0 ? data?.[0]?.length : 1
        for (let i = 1; i <= columnCount; i++) {
            worksheet.getColumn(i).width = 13;
            const cell = worksheet.getCell(`${columnNumberToLetter(i)}1`);
            cell.value = data?.[0]?.[i - 1] ? formatString(data?.[0]?.[i - 1]) : data?.[0]?.[i - 1];
            cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: '202020' },
            };
            cell.border = {
                left: { style: 'thin', color: { argb: 'FFFFFFFF' } }
            };
            cell.alignment = {
                vertical: 'middle',
                horizontal: 'center'
            };
            cell.font = {
                color: { argb: 'FFFFFF' },
                name: 'Avenir',
                bold: true,
                size: 12
            };
        }
        data?.slice(1).forEach((row: any, rowIndex: number) => {
            row?.forEach((cellValue: any, colIndex: number) => {
                const cell = worksheet.getCell(`${columnNumberToLetter(colIndex + 1)}${rowIndex + 2}`);
                cell.value = cellValue;
                cell.alignment = { vertical: 'middle', horizontal: 'center' };
            });
        });
        workbook.xlsx.writeBuffer().then((buffer: any) => {
            const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.download = `Pricing_catalog.xlsx`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(url);
        });
        setLoadingDownload(false)
        setShowDownloadPricingModal(false)
        setShowDownloadSampleModal(false)
        document.body.style.overflow = 'auto';
    };

    const onDownloadTemplate = async (excel: boolean) => {
        setLoadingDownload(true)
        try {
            const response = await partnerPricingTemplateMutation({ assetTemplateId: templateSelected?.value || '' }).unwrap()
            if (excel) {
                downloadExcel(response || [])
            } else {
                setTemplateData(response || [])
            }
        } catch (err) {
            setLoadingDownload(false)
        }
    }

    const onDownloadPricing = async (excel: boolean) => {
        setLoadingDownloadPricing(true)
        try {
            const formatData = {
                brokerId: partnerSelected?.value || '',
                data: {
                    filters: {
                        country: marketSelected ? marketSelected?.displayName : '',
                        assetTemplateId: templateSelected?.value,
                        download: true
                    }
                }
            }
            const response = await dispatch(tryToDownloadPricingAdmin(formatData)).unwrap()
            if (excel) {
                downloadExcel(response || [])
            } else {
                setDataPricing(response || [])
            }
        } catch (err) {
            // error here
            setLoadingDownloadPricing(false)
        }
    }

    useEffect(() => {
        if (dataPricing && loadingDownloadPricing) {
            csvLink2.current.link.click()
            setLoadingDownloadPricing(false)
            setShowDownloadPricingModal(false)
            document.body.style.overflow = 'auto';
        }
    }, [dataPricing])

    useEffect(() => {
        if (templateData && loadingDownload) {
            csvLink.current.link.click()
            setShowDownloadSampleModal(false)
            setLoadingDownload(false)
            document.body.style.overflow = 'auto';
        }
    }, [templateData])

    const onToggleImportCsvModal = () => {
        setShowImportCsvModal(!showImportCsvModal)
    }

    const onCloseTemplateModal = () => {
        if (!showDownloadSampleModal) {
            document.body.style.overflow = 'auto';
        } else {
            document.body.style.overflow = 'hidden';
        }
        setShowDownloadSampleModal(!showDownloadSampleModal)
    }

    const onTogglePricingModal = () => {
        if (!showDownloadPricingModal) {
            document.body.style.overflow = 'auto';
        } else {
            document.body.style.overflow = 'hidden';
        }
        setShowDownloadPricingModal(!showDownloadPricingModal)
    }

    return (
        <div className='pr-5'>
            <div className='mt-7 mb-3'>
                <div>
                    <div className={`min-w-full py-1 px-2 flex flex-row justify-end items-center bg-[#fefefe]`}>
                        <Button
                            onClickButton={() => onCloseTemplateModal()}
                            label={'Download Sample'}
                            className={'btn-main flex flex-row align-start my-2 !py-2'}
                        />
                        <CSVLink
                            ref={csvLink}
                            data={(templateData && templateData?.length > 0) && templateData?.filter((item: any, idx: number) => idx > 0) || []}
                            headers={templateData?.[0] ? [...templateData?.[0], 'pricing'] : []}
                            className={'none'}
                            target={'_blank'}
                            filename={`Pricing-catalog-sample.csv`}
                        >
                        </CSVLink>
                        <Button
                            onClickButton={() => onTogglePricingModal()}
                            label={'Download Pricing'}
                            dataQa={'download-pricing'}
                            className={'btn-main flex flex-row align-start my-2 ml-2 !py-2'}
                        />
                        <CSVLink
                            ref={csvLink2}
                            data={dataPricing?.filter((item: any, idx: number) => idx > 0) || []}
                            headers={dataPricing?.[0] || []}
                            className={'none'}
                            target={'_blank'}
                            filename={`Pricing-admin-broker.csv`}
                        >
                        </CSVLink>
                        <Button
                            onClickButton={() => (!accessControl || pageAccess?.actions?.['Import Pricing']) && onToggleImportCsvModal()}
                            label={'Import Pricing'}
                            dataQa={'import-pricing'}
                            className={`${(!accessControl || pageAccess?.actions?.['Import Pricing']) ? 'btn-main' : 'btn-main-disable'} flex flex-row align-start my-2 ml-2 !py-2`}
                        />
                    </div>
                    <AdminPricingTable
                        rows={rows}
                        paginationData={paginationData}
                        pricingLoading={pricingLoading}
                        markets={markets}
                        state={state}
                        selectedMarket={marketSelected?.value || ''}
                        setPaginationState={setPaginationState}
                    />
                    <ImportPricingCsvModal
                        openImportModal={showImportCsvModal}
                        handleCloseModal={onToggleImportCsvModal}
                        sectors={sectors || []}
                        partnerId={partnerSelected?.value || ''}
                        marketSelected={marketSelected?.value}
                    />
                    {showDownloadSampleModal &&
                        <DownloadSampleModal
                            openModal={showDownloadSampleModal}
                            accessControl={accessControl}
                            actions={pageAccess?.actions}
                            loadingDownload={loadingDownload}
                            onDownloadTemplate={onDownloadTemplate}
                            handleClose={onCloseTemplateModal}
                        />
                    }
                    {showDownloadPricingModal &&
                        <DownloadPricingModal
                            openModal={showDownloadPricingModal}
                            accessControl={accessControl}
                            actions={pageAccess?.actions}
                            loadingDownload={loadingDownloadPricing}
                            onDownloadPricing={onDownloadPricing}
                            handleClose={onTogglePricingModal}
                        />
                    }
                </div>
            </div>
        </div>
    )
}
export default DetailsStep;