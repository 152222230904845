import React, { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { tryToAddConversationAiAgent, tryToAddNextConversationAiAgent } from '../../../store/brokers/ai-agents/aiAgentsSlice';
import { useAppDispatch } from '../../../app/hooks';
import { TAgents } from '../../../interfaces/ai-agents/TAgents';

const PROCEED_OPTIONS = [
	{
		title: 'Upload my list',
		description: 'Click attach button and upload a csv file with all the items that you want to sell. If the condition of the item is not included price might be affected',
		message: 'Upload my list',
	},
	{
		title: 'Download Template than re-upload',
		description: 'Download a csv template and then list your items and re-upload.',
		message: 'Download template then reupload'
	},
	{
		title: 'AI Grading',
		description: 'You simply need to have a camera to start taking pictures of what phones you are selling, AI will grade the item, and you will get a specific price for each one.',
		message: 'Ai Grading'
	}
]

const HowToProceed = ({
	message,
	type,
}: {
	message: string,
	type?: TAgents
}) => {
	const {id} = useParams();
	const dispatch = useAppDispatch();

	const handleClick = useCallback(async (answer: string) => {
		const addChat = await dispatch(tryToAddConversationAiAgent({ answer, chatId: id || '', type })).unwrap();
		if (addChat.success && !addChat.finalStep) {
			await dispatch(tryToAddNextConversationAiAgent({chatId: id || ''})).unwrap();
		}
 	}, [id]);

	return (
		<div className='w-full'>
			<p>{message}</p>
			<div className='flex flex-wrap my-4 -mx-2'>
				{PROCEED_OPTIONS.map(option => (
					<div key={option.message} className='w-full md:w-4/12 px-2 flex-1'>
						<button className='bg-[#F7A21E33] rounded-2xl h-full text-left flex flex-col w-full hover:bg-opacity-80' onClick={() => handleClick(option.message)} type='button'>
							<h4 className='text-agentDetails-secondaryDescription text-xl font-bold my-2 px-4'>{option.title}</h4>
							<p className='text-agentDetails-secondaryDescription text-sm font-normal px-4 mb-2'>{option.description}</p>
							<div className='mt-auto w-full p-4'>
								<div className='bg-white flex justify-center items-center h-52 w-full'>
									<img alt='camera' src='/assets/ai-agents/camera.svg' />
								</div>
							</div>
						</button>
					</div>
				))}
			</div>
		</div>
	)
}

export default HowToProceed;