import React, { useCallback, useEffect, useState } from 'react';
import { IMatches } from '../../../interfaces/ai-agents/IItem';
import Button from '../../../shared/button';
import { useAppDispatch } from '../../../app/hooks';
import { tryToAddConversationAiAgent, tryToAddNextConversationAiAgent, tryToDownloadConfirmedItems } from '../../../store/brokers/ai-agents/aiAgentsSlice';
import { useParams } from 'react-router-dom';
import { CSVLink } from 'react-csv';
import { TAgents } from '../../../interfaces/ai-agents/TAgents';

const GetConfirmationDetailsItem = ({
	message,
	getConfirmedItems,
	isLoading,
	type,
	confirmItemsToBuy,
	stepDetails
}: {
	message: string,
	getConfirmedItems?: IMatches[],
	isLoading: boolean,
	type?: TAgents,
	confirmItemsToBuy?: boolean,
	stepDetails?: {stepNr: number, stepAnswer: string}
}) => {
	const dispatch = useAppDispatch();
	const { id } = useParams();
	const [csvData, setCsvData] = useState<object[]>();

	const handleConfirm = useCallback(async () => {
		const addChat = await dispatch(tryToAddConversationAiAgent({ answer: 'confirm', chatId: id || '', type, confirmItemsToBuy: true })).unwrap();
		if (addChat.success && !addChat.finalStep) {
			await dispatch(tryToAddNextConversationAiAgent({ chatId: id || '', type })).unwrap();
		}
	}, [id, getConfirmedItems]);

	const handleDownloadPdf = useCallback(async () => {
		if (getConfirmedItems) {
			const pdf = await dispatch(tryToDownloadConfirmedItems(getConfirmedItems)).unwrap();
			setCsvData(pdf);
		}
	}, [getConfirmedItems]);

	useEffect(() => {
		handleDownloadPdf()
	}, []);

	return (
		<div className='w-full'>
			<p>{message}</p>
			<div className='w-full px-2 mt-4'>
				<table className='w-full rounded-lg mt-2 mb-5'>
					<tbody>

						{getConfirmedItems?.map((match, index: number) =>
							<tr key={index} >
								<td className='bg-[#F8F9FC] p-2 border-b border-dashed text-sm'>{index + 1}</td>
								<td className='bg-[#F8F9FC] p-2 border-b border-dashed text-sm'>{match.asset.make} {match.asset.model} {match.asset.storage},  {match.asset.condition}, {match.asset.network}</td>
								<td className='bg-[#F8F9FC] p-2 border-b border-dashed text-sm'>{match.inventory.sales_grade}</td>
								<td className='bg-[#F8F9FC] p-2 border-b border-dashed text-sm'>{match.quantity}</td>
								<td className='bg-[#F8F9FC] p-2 border-b border-dashed text-sm text-[#8A8A8E]'>
									{new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' }).format(match.price)}
								</td>
							</tr>
						)}
					</tbody>
					<tfoot>
						<tr>
							<td className='pt-5'>
								{!confirmItemsToBuy && stepDetails?.stepAnswer ? <p className='text-[#00B67A] text-sm font-normal'>{stepDetails.stepAnswer}</p> :<Button
									disabled={isLoading}
									loading={isLoading}
									className='text-[#00B67A] text-sm font-normal hover:underline'
									label={`Confirm (${getConfirmedItems?.length || 0})`}
									onClickButton={handleConfirm}
								/>}
							</td>
							<td className='pt-5'>
								{csvData && <CSVLink
									data={csvData}
									className='text-[#f7a21d] text-sm font-normal hover:underline'
									filename={'match-items.csv'}
								>
									Download as CSV
								</CSVLink>}
							</td>
						</tr>
					</tfoot>
				</table>
			</div>
		</div>
	)
}

export default GetConfirmationDetailsItem;