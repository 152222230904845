import React, { useEffect, useState } from 'react';
import Select from '../../../../shared/select';
import Button from '../../../../shared/button';
import { useForm } from 'react-hook-form';
import CustomerCallsTable from './CustomerCallsTable';
import { IPaginationPayload } from '../../../../interfaces/shared/IPaginationPayload';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { tryToGetBatchInformations } from '../../../../store/brokers/ai-agents-call/aiAgentsCallSlice';
import { useGetCurrentUserQuery } from '../../../../store/user/userApi';
import { aiAgentService } from '../../../../store/brokers/ai-agents-call/aiAgentsCallApi';
import { CSVLink } from 'react-csv';

const CustomerCall = () => {
	const [batchIndex, setBatchIndex] = useState<number>(0);
	const [isLoading, setIsLoading] = useState<boolean>();
	const {batches} = useAppSelector((state) => state?.aiAgentsCall);

	const csvLink1 = React.useRef() as React.MutableRefObject<any>;
	const [downloadedRows, setDownloadedRows] = useState<string[][]>();

	const BATCH_FILTERS = [...batches].map((batch, index) => ({label: batch._id + index, value: batch._id}))

	const dispatch = useAppDispatch();
	const {data: user} = useGetCurrentUserQuery();

	const [paginationState, setPaginationState] = useState<IPaginationPayload>({
        pageNumber: 1,
        pageSize: 10,
    });
	const {register, handleSubmit, watch} = useForm<{batchFilter: string}>();

	const customerCallsState = useAppSelector((state) => state?.aiAgentsCall);

	useEffect(()=>{
		[...BATCH_FILTERS].forEach((batch, index) => {
		 	if (batch.value === watch('batchFilter'))
		 		setBatchIndex(index)
		})
	}, [watch('batchFilter')])

	const onGetBatchInformations = async () => {
		try {
			const fetchData = {
				...paginationState || {},
				brokerId: user?.id || '',
				// brokerId: 'a434ad47e8cf272488b5a63b',
				batchId: batches[batchIndex]._id,
				status: "Didn't Response"
			}
			await dispatch(tryToGetBatchInformations(fetchData)).unwrap()
		} catch (err) {
			console.log({err})
			// err
		}
    }

	useEffect(() => {
		if (batches && batches[batchIndex]?._id)
			onGetBatchInformations()
    }, [paginationState, batches, batchIndex])


	const onSubmit = ()=>{}

	const handleDownload = async () => {
		setIsLoading(true)
		try {
			const {data} = await aiAgentService.tryToDownloadBatchPdf({
				brokerId: user?.id || '',
				// brokerId: 'a434ad47e8cf272488b5a63b',
			});
			setDownloadedRows(data.data)
			setIsLoading(false)
			setTimeout(()=>{
				csvLink1.current.link.click();
			}, 1000)
		} catch (e){
			console.log({e});
			setIsLoading(false)
		}
	}

	
	return (
		<div className='my-5'>
			<h3 className='font-dm_sansbold text-xl font-bold text-title flex flex-wrap'>
				Customer Calls 
				<form className='flex flex-col md:flex-row font-dm_sansregular md:ml-auto pl-4' onSubmit={handleSubmit(onSubmit)}>
					<div className='text-sm mt-3'>Show: </div> 
					<Select register={register('batchFilter')} className=' text-sm font-normal mb-0 p-3 rounded-xl md:mx-4' options={BATCH_FILTERS} />
					<Button className='bg-gradient-to-br from-gradient-start to-gradient-end py-2 px-5 text-sm whitespace-nowrap h-11 rounded-lg' onClickButton={handleDownload} loading={isLoading} label='Download Transcript' type='submit' />
					<CSVLink
						ref={csvLink1}
						data={downloadedRows?.filter((_item, idx: number) => idx > 0 ) || []}
						headers={downloadedRows?.[0] || []}
						className={'none'}
						target={'_blank'}
						filename={`Calls-transcripts-all.csv`}
					></CSVLink>
				</form>
			</h3>
			<div className='w-full'>
				<CustomerCallsTable 
					rows={customerCallsState?.customerCalls?.elements || []}
					rowsLoading={customerCallsState.itemsAreLoading}
					paginationData={customerCallsState?.customerCalls?.page}
					setPaginationState={setPaginationState}
				/>
			</div>
			
		</div>
	)
}

export default CustomerCall;